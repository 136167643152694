
import {createSlice} from "@reduxjs/toolkit";

const initialMerchantsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  data: null,
  entities: null,
  merchantForEdit: undefined,
  merchantPaynowRates: null,
  merchantPaynowRateForEdit: undefined,
  merchantPaylaterRates: null,
  merchantPaylaterRatesForEdit: undefined,
  merchantAPIKeys: null,
  merchantAPIKeyForEdit: undefined,
  merchantEventSignature: { signatureKey: "" },
  merchantExchangeRates: null,
  merchantExchangeRateForEdit: undefined,
  merchantRedirectUrls: [],
  merchantShopifyConfigs: [],
  merchantShopifyConfigForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const merchantsSlice = createSlice({
  name: "merchants",
  initialState: initialMerchantsState,
  reducers: {
    catchError: (state, action) => {
      state.error = 'Error: ' + (
        action.payload.error.response?.data?.message
          || action.payload.error.clientMessage
          || action.payload.error.message
      );
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // findMerchants
    merchantsFetched: (state, action) => {
      const { totalCount, data, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.data = data;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // getMerchantById
    merchantFetched: (state, action) => {
      state.actionsLoading = false;
      state.merchantForEdit = action.payload.merchantForEdit;
      if (state.merchantPaynowRateForEdit && state.merchantPaynowRateForEdit.merchantID !== state.merchantForEdit.merchantID) {
        state.merchantPaynowRateForEdit = null;
      }
      state.error = null;
    },
    // createMerchant
    merchantCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    // updateMerchant
    merchantUpdated: (state, action) => {
      state.actionsLoading = false;
      state.merchantForEdit = action.payload.merchant;
      state.error = null;
      if (state.data) {
        state.data = state.data.map(entity => {
          if (entity.merchantID === action.payload.merchant.merchantID) {
            return action.payload.merchant;
          }
          return entity;
        });
      }
      if (state.entities) {
        state.entities = state.entities.map(entity => {
          if (entity.merchantID === action.payload.merchant.merchantID) {
            return action.payload.merchant;
          }
          return entity;
        });
      }
    },
    merchantDocDownloaded: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    merchantCustomerDataDownloaded: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    merchantEventSignatureFetched: (state, action) => {
      const { data } = action.payload;
      state.error = null;
      state.merchantEventSignature = { ...data };
    },
    merchantEventSignatureUpdated: (state, action) => {
      state.actionsLoading = false;
    },
    editMerchantAPIKey: (state, action) => {
      state.error = null;
      state.merchantAPIKeyForEdit = action.payload
    },
    merchantAPIKeysFetched: (state, action) => {
      const { data } = action.payload;
      state.error = null;
      state.merchantAPIKeys = data;
    },
    merchantAPIKeyUpdated: (state, action) => {
      const { merchantAPIKey } = action.payload
      state.actionsLoading = false;
      if (merchantAPIKey.secret) {
        state.merchantAPIKeyForEdit = merchantAPIKey;
      } else {
        state.merchantAPIKeyForEdit = undefined;
      }
      if (state.merchantAPIKeys) {
        let newRec = true
        state.merchantAPIKeys = state.merchantAPIKeys.map(entity => {
          if (entity.keyID === merchantAPIKey.keyID) {
            newRec = false
            return merchantAPIKey;
          }
          return entity;
        });
        if (newRec) {
          const removedSecretKey = { ...merchantAPIKey, secret: undefined }
          state.merchantAPIKeys.unshift(removedSecretKey)
        }
      }
    },
    merchantRedirectUrlsFetched: (state, action) => {
      const { data } = action.payload;
      state.error = null;
      state.listLoading = false;
      state.actionsLoading = false;
      state.merchantRedirectUrls = data;
    },
    editMerchantExchangeRate: (state, action) => {
      state.error = null;
      state.merchantExchangeRateForEdit = action.payload
    },
    merchantExchangeRatesFetched: (state, action) => {
      const { data } = action.payload;
      state.error = null;
      state.merchantExchangeRates = data;
    },
    merchantExchangeRateUpdated: (state, action) => {
      const { exchangeRate } = action.payload;
      state.actionsLoading = false;
      state.merchantExchangeRateForEdit = exchangeRate;
      if (state.merchantExchangeRates) {
        let newRec = true;
        state.merchantExchangeRates = state.merchantExchangeRates.map(entity => {
          if (entity.rateID === exchangeRate.rateID) {
            newRec = false;
            return exchangeRate
          }
          return entity;
        });
        if (newRec) {
          state.merchantExchangeRates.unshift(exchangeRate);
        }
      }
    },
    editMerchantPaynowRate: (state, action) => {
      state.error = null;
      state.merchantPaynowRateForEdit = action.payload
    },
    merchantPaynowRatesFetched: (state, action) => {
      const { data } = action.payload;
      state.error = null;
      state.merchantPaynowRates = data;
    },
    merchantPaynowRateUpdated: (state, action) => {
      const { merchantRate } = action.payload
      state.actionsLoading = false;
      state.merchantPaynowRateForEdit = merchantRate;
      if (state.merchantPaynowRates) {
        let newRec = true
        state.merchantPaynowRates = state.merchantPaynowRates.map(entity => {
          if (entity.rateID === merchantRate.rateID) {
            newRec = false
            return merchantRate;
          }
          return entity;
        });
        if (newRec) {
          state.merchantPaynowRates.unshift(merchantRate)
        }
      }
    },
    merchantPaylaterRatesFetched: (state, action) => {
      const { data } = action.payload;
      state.error = null;
      state.merchantPaylaterRates = data;
    },
    merchantShopifyConfigsFetched: (state, action) => {
      const { data } = action.payload;
      state.error = null;
      state.merchantShopifyConfigs = data;
    },
    editMerchantShopifyConfig: (state, action) => {
      state.error = null;
      state.merchantShopifyConfigForEdit = action.payload
    },
    merchantShopifyConfigUpdated: (state, action) => {
      const { data } = action.payload
      state.merchantShopifyConfigForEdit = data
      state.actionsLoading = false;
      if (state.merchantShopifyConfigs) {
        let newRec = true
        state.merchantShopifyConfigs = state.merchantShopifyConfigs.map(entity => {
          if (entity.shopDomain === data.shopDomain) {
            newRec = false
            return data;
          }
          return entity;
        });
        if (newRec) {
          state.merchantShopifyConfigs.unshift(data)
        }
      }
    },
    merchantShopifyConfigDeleted: (state, action) => {
      const { shopDomain } = action.payload
      state.actionsLoading = false;
      state.merchantShopifyConfigForEdit = undefined
      state.merchantShopifyConfigs = state.merchantShopifyConfigs.filter(entity => {
        return (entity.shopDomain !== shopDomain) 
      });
    }
  }
});

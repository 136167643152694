export const OrderStatusCssClasses = (status) => {
  switch (status) {
    case 'New':
      return 'info';
    case 'Active':
    case 'Complete':
      return 'success';
    case 'Overdue':
    default:
      return 'danger';
  }
}

export const defaultSorted = [{ dataField: "createdAt", order: "desc" }];
export const sizePerPageList = [
  { text: "5", value: 5 },
  { text: "10", value: 10 },
  { text: "25", value: 25 },
  { text: "50", value: 50 },
  { text: "100", value: 100 }
];
export const initialFilter = {
  filter: {
    status: undefined,
  },
  sortOrder: "desc", // asc||desc
  sortField: "createdAt",
  pageNumber: 1,
  pageSize: 10
};

import { createSlice } from "@reduxjs/toolkit"

const initialGiftcardsState = {
  listLoading: false,
  actionsLoading: false,
  productCount: 0,
  products: null,
  productDetails: null,
  inventoryCount: 0,
  inventoryItems: null,
  inventoryItemDetails: null,
  orderCount: 0,
  orders: null,
  orderDetails: null,
  waitingOrderCount: 0,
  waitingOrders: null,
  waitingOrderDetails: null,
  cardCount: 0,
  cards: null,
  cardDetails: null,
}

export const callTypes = {
  list: "list",
  action: "action"
}

export const giftcardsSlice = createSlice({
  name: "giftcards",
  initialState: initialGiftcardsState,
  reducers: {
    catchError: (state, action) => {
      state.error = 'Error: ' + (
        action.payload.error.response?.data?.message
        || action.payload.error.clientMessage
        || action.payload.error.message
      )
    },
    startCall: (state, action) => {
      state.error = null
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    endCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
    },

    // refreshProducts
    giftcardProductsRefreshed: (state, action) => {
      state.error = null
    },

    // findGiftcardProducts
    giftcardProductsFetched: (state, action) => {
      const { totalCount, data } = action.payload
      state.error = null
      state.products = data
      state.productCount = totalCount
    },

    // getGiftcardProduct
    giftcardProductFetched: (state, action) => {
      const { data } = action.payload
      state.error = null
      state.productDetails = data
    },

    // findGiftcardInventoryItems
    giftcardInventoryItemsFetched: (state, action) => {
      const { totalCount, data } = action.payload
      state.error = null
      state.inventoryItems = data
      state.inventoryCount = totalCount
    },

    // getGiftcardInventoryItem
    giftcardInventoryItemFetched: (state, action) => {
      const { data } = action.payload
      state.error = null
      state.inventoryItemDetails = data
    },

    // findGiftcardOrders
    giftcardOrdersFetched: (state, action) => {
      const { totalCount, data } = action.payload
      state.error = null
      state.orders = data
      state.orderCount = totalCount
    },

    // getGiftcardOrderDetails
    giftcardOrderDetailsFetched: (state, action) => {
      const { data } = action.payload
      state.error = null
      state.orderDetails = data
    },

    // orderVoided
    giftcardOrderVoided:(state, action) => {
      const { data } = action.payload
      state.error = null
      state.orderDetails = data
    },

    // waiting list orders
    giftcardWaitingOrdersFetched: (state, action) => {
      const { totalCount, data } = action.payload
      state.error = null
      state.waitingOrders = data
      state.waitingOrderCount = totalCount
    },
    
    // waiting list order details
    giftcardWaitingOrderDetailsFetched: (state, action) => {
      const { data } = action.payload
      state.error = null
      state.waitingOrderDetails = Array.isArray(data) && data.length > 0 ? data[0]: data
    },

    giftcardWaitingOrderCancelled: (state, action) => {
      const { data } = action.payload
      state.error = null
      state.waitingOrderDetails = Array.isArray(data) && data.length > 0 ? data[0]: data
    },

    // findGiftcards
    customerGiftcardsFetched: (state, action) => {
      const { totalCount, data } = action.payload
      state.error = null
      state.cards = data
      state.cardCount = totalCount
    },

    // getGiftcardDetails
    giftcardDetailsFetched: (state, action) => {
      const { data } = action.payload
      state.error = null
      state.cardDetails = data
    },

    // refreshCardBalance
    giftcardBalanceRefreshed: (state, action) => {
      const { data } = action.payload
      state.error = null
      state.cardDetails = data
    },

    // giftcardVoided
    giftcardVoided: (state, action) => {
      const { data } = action.payload
      state.error = null
      state.cardDetails = data
    },
  }
})

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  data: null,
  entities: null,
  promotionMessagesCount: 0,
  promotionMessages: null,
  promotionMessageForEdit: undefined,
  uploadedBanner: "",
  isSendSMSRequested: false,
  serviceStatusForEdit: undefined,
  error: null,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const toolsSlice = createSlice({
  name: "tools",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error =
        "Error: " +
        (action.payload.error.response?.data?.message ||
          action.payload.error.clientMessage ||
          action.payload.error.message);
      state.loading = false;
    },
    startCall: (state, action) => {
      state.error = null;
      state.loading = true;
    },
    customersDownloaded: (state, action) => {
      state.loading = false;
      state.error = null;
    },
    sensSMSStartRequest: (state, action) => {
      state.isSendSMSRequested = false;
      state.error = null;
      state.loading = true;
    },
    sendSMSRequested: (state, action) => {
      state.isSendSMSRequested = true;
      state.loading = false;
      state.error = null;
    },
    serviceStatusNew: (state) => {
      state.actionsLoading = false;
      state.error = null;
      state.serviceStatusForEdit = null;
    },
    // findserviceStatuses
    serviceStatusesFetched: (state, action) => {
      const { totalCount, data, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.data = data;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createserviceStatus
    serviceStatusCreated: (state, action) => {
      const { data } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.serviceStatusForEdit = data;
      if (state.data) {
        state.data.unshift(data);
      }
      console.log("action.payload", action.payload);
    },
    // updateserviceStatus
    serviceStatusUpdated: (state, action) => {
      const { data } = action.payload;
      state.error = null;
      state.actionsLoading = false;
      if (state.data) {
        state.data = state.data.map((entity) => {
          if (entity.ID === data.ID) {
            return data;
          }
          return entity;
        });
        state.entities = state.data;
      }
    },
    bannerUploaded: (state, action) => {
      const { filename } = action.payload;
      state.uploadedBanner = filename;
    },
    bannerUploadClear: (state) => {
      state.uploadedBanner = "";
    },
    promotionMessageNew: (state) => {
      state.actionsLoading = false;
      state.error = null;
      state.promotionMessageForEdit = null;
    },
    // getPromotionMessages
    promotionMessagesFetched: (state, action) => {
      const { totalCount, data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.promotionMessages = [];

      data.forEach((d) => {
        const pm = {
          id: d?.ID || 0,
          configType: d?.configType || "",
          configKey: d?.configKey || "",
          language: d?.language || "en",
          appliedToMessageTypes: d.content?.appliedToMessageTypes || [],
          blockStyle: d.content?.blockStyle || "",
          positionID: d.content?.positionID || "",
          imageURL: d.content?.imageURL || "",
          linkURL: d.content?.linkURL || "",
          title: d.content?.title || "",
          textContent: d.content?.textContent || "",
          sortingSeq: d?.sortingSeq || 0,
          status: d?.status || "active",
          effectiveAt: d?.effectiveAt || 0,
          expiredAt: d?.expiredAt || 0,
        };
        state.promotionMessages.push(pm);
      });
      state.promotionMessagesCount = totalCount;
    },
    // createPromotionMessage
    promotionMessageCreated: (state, action) => {
      const { data } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      const pm = {
        id: data?.ID || 0,
        configType: data?.configType || "",
        configKey: data?.configKey || "",
        language: data?.language || "en",
        appliedToMessageTypes: data.content?.appliedToMessageTypes || [],
        blockStyle: data.content?.blockStyle || "",
        positionID: data.content?.positionID || "",
        imageURL: data.content?.imageURL || "",
        linkURL: data.content?.linkURL || "",
        title: data.content?.title || "",
        textContent: data.content?.textContent || "",
        sortingSeq: data?.sortingSeq || 0,
        status: data?.status || "active",
        effectiveAt: data?.effectiveAt || 0,
        expiredAt: data?.expiredAt || 0,
      };
      state.promotionMessageForEdit = pm;
      if (state.promotionMessages) {
        state.promotionMessages.unshift(pm);
      }
    },
    // updatePromotionMessage
    promotionMessageUpdated: (state, action) => {
      const { data } = action.payload;
      state.error = null;
      state.actionsLoading = false;
      if (state.promotionMessages) {
        state.promotionMessages = state.promotionMessages.map((entity) => {
          if (entity.id === data.ID) {
            return {
              id: data?.ID || 0,
              configType: data?.configType || "",
              configKey: data?.configKey || "",
              language: data?.language || "en",
              appliedToMessageTypes: data.content?.appliedToMessageTypes || [],
              blockStyle: data.content?.blockStyle || "",
              positionID: data.content?.positionID || "",
              imageURL: data.content?.imageURL || "",
              linkURL: data.content?.linkURL || "",
              title: data.content?.title || "",
              textContent: data.content?.textContent || "",
              sortingSeq: data?.sortingSeq || 0,
              status: data?.status || "active",
              effectiveAt: data?.effectiveAt || 0,
              expiredAt: data?.expiredAt || 0,
            };
          }
          return entity;
        });
      }
    },
  },
});

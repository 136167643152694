/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import moment from "moment";
import { useHtmlClassService } from "../../../layout";
import { WidgetSpinner } from "../WidgetSpinner"

export function DivitBarStatsWidget({
  className,
  baseColor,
  id,
  timeAttribute,
  valueAttribute,
  title,
  tooltipFormatter,
  tooltipLabel,
}) {  
  const uiService = useHtmlClassService();

  const data = useSelector(
    state => state.reports.orderReport,
    shallowEqual
  );

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseSuccess: objectPath.get(
        uiService.config,
        `js.colors.theme.base.${baseColor}`
      ),
      colorsThemeLightSuccess: objectPath.get(
        uiService.config,
        `js.colors.theme.light.${baseColor}`
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
      tooltipFormatter,
      tooltipLabel,
    };
  }, [uiService, baseColor, tooltipFormatter, tooltipLabel]);

  useEffect(() => {
    const element = document.getElementById(id);

    if (!element || !data) {
      return;
    }

    const xData = data.map(i => moment(i[timeAttribute]).format("DD MMM"));
    const yData = data.map(i => i[valueAttribute]);

    const options = getChartOption(xData, yData, layoutProps);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [data, layoutProps, id, timeAttribute, valueAttribute]);
  
  if (!data) return <WidgetSpinner className={className} />
  
  const totalAmount = data
    .map(i => i[valueAttribute])
    .reduce((sum, item) => sum + item, 0);

  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-body container p-0">
        <div className="card-spacer row no-gutter">
          <div className="col-12 d-flex justify-content-between">
            <div className="d-flex flex-column">
              <span className="text-dark-75 font-weight-bolder font-size-h4">
                { title }
              </span>
              <span className="text-dark-50 font-weight-bold font-size-h4">
                {tooltipFormatter(totalAmount)}
              </span>
            </div>
          </div>
        </div>
        <div
          id={id}
          className="card-rounded-bottom"
          style={{ height: "150px" }}
        ></div>
      </div>
    </div>
  );
}

const getChartOption = (xData, yData, layoutProps) => {
  const options = {
    series: [
      {
        name: layoutProps.tooltipLabel,
        data: yData,
      }
    ],
    chart: {
      type: "bar",
      height: 250,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        }
      },
      sparkline: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: ["90%"],
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: xData,
      tickAmount: Math.min(xData.length, 4),
      tickPlacement: "on",
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
      labels: {
        show: true,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
        rotate: 0,
      },
    },
    yaxis: {
      forceNiceScale: true,
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
      labels: {
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: layoutProps.fontFamily,
      },
      y: {
        formatter: layoutProps.tooltipFormatter,
      }
    },
    colors: [layoutProps.colorsThemeBaseSuccess],
  };
  return options;
}

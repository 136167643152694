/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { FormattedMessage, injectIntl } from "react-intl";
import TextField from '@material-ui/core/TextField';
import { CaptchaProvider, useCaptcha } from "../../Signup/CaptchaProvider";

import { actions } from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import { fetchMerchant } from "@/app/modules/Merchants/_redux/merchants/merchantsActions";
import {useHtmlClassService} from "@/_metronic/layout/_core/MetronicLayout";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/


const initialValues = {
  email: "",
  password: "",
};

const headerStyle = {
  fontWeight: 800,
  letterSpacing: '5px',
}

function Login(props) {
  const { intl } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { twoFactor, scope, pid } = useSelector(({ auth }) => auth, shallowEqual);
  const { merchantForEdit } = useSelector(({ merchants }) => merchants, shallowEqual)
  const { executeRecaptcha } = useCaptcha()
  const uiService = useHtmlClassService();
  
  useEffect(() => {
    if (scope === "setup") {
      history.replace('/auth/setup');
    } else if (scope === "2fa") {
      history.replace('/auth/authenticator');
    } else if (twoFactor === "unset") {
      if (!merchantForEdit) {
        dispatch(fetchMerchant(pid))
      } else {
        if (merchantForEdit.enforce2FA === "yes") dispatch(actions.twoFactorAuthenticated());
        else if (merchantForEdit.enforce2FA === "no") dispatch(actions.twoFactorLater());
        else history.replace('/auth/reminder');
      }
    } else if (twoFactor === "bypass") {
      history.replace('/dashboard');
    } else if (twoFactor === "unauthenicated") {
      history.push('/auth/setup');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scope, twoFactor, merchantForEdit]);

  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    rememberMe: Yup.boolean(),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      let recapResp = '';
      try {
          recapResp = await executeRecaptcha('MERCHANT_FORGOT');
      } catch (err) {
          console.error(err);
      }
      login(values.email, values.password, recapResp)
        .then(({ data: { token, expire } }) => {
          setLoading(false);
          dispatch(actions.login(token, expire));
        })
        .catch(() => {
          setLoading(false);
          setSubmitting(false);
          setStatus(
            intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_LOGIN",
            })
          );
        });
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10">
        <h3 className="font-size-h1 mb-10" style={headerStyle}>
          <img alt="logo" src={uiService.getStickyLogo()} style={{height: '42px'}}/>
        </h3>
        <p className="text-muted font-weight-bold">
          Welcome back! Please login to your account.
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}

        <div className="form-group fv-plugins-icon-container">
          <TextField
            id="email-input"
            name="email"
            label="Email"
            className={getInputClasses("email")}
            type="email"
            margin="normal"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <TextField
            id="password-input"
            name="password"
            label="Password"
            className={getInputClasses("password")}
            type="password"
            margin="normal"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <div className="input-group w-50 align-items-center">
            <input
              type="checkbox"
              id="remember-me"
              className="mr-2"
              aria-label="Checkbox for remember me"
              {...formik.getFieldProps("rememberMe")}
            /> 
            <label htmlFor="remember-me" className="m-0">Remember Me</label>
          </div>
          <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link>
        </div>
        <div className="form-group d-flex justify-content-between align-items-center">
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-block btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Merchant Login</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

const LoginClass = props => (
  <CaptchaProvider>
      <Login {...props} />
  </CaptchaProvider>
)

export default injectIntl(LoginClass);

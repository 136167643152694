/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { Input, Select } from "../../../../_metronic/_partials/controls";
import * as ticketsActions from "../../../../app/modules/Tickets/_redux/tickets/ticketsActions";

// Validation schema
const TicketCreateSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  label: Yup.string().required("Label is required"),
  content: Yup.string().required("Content is required"),
  internal: Yup.boolean(),
});

export function DivitCreateFeedWidget({ service, className, orderId }) {
  const dispatch = useDispatch();
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onClickCreate = () => {
    setShowCreateForm(true);
  };

  const submitTicket = (values) => {
    const { title, label, content, internal } = values;
    setIsSubmitting(true);
    dispatch(
      ticketsActions.createTicket(service, {
        orderID: orderId,
        instalmentID: "",
        title,
        content,
        label,
        private: internal,
      })
    ).then(() => {
      setShowCreateForm(false);
      setIsSubmitting(false);
    });
  };

  return (
    <div className={`card card-custom gutter-b position-relative ${className}`}>
      <div className="card-body d-flex flex-column justify-content-center">
        {!showCreateForm && (
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            role="button"
            onClick={onClickCreate}
          >
            <i className="flaticon2-plus text-info icon-lg pb-3"></i>
            <span>Create ticket</span>
          </div>
        )}
        {showCreateForm && (
          <Formik
            enableReinitialize={true}
            validationSchema={TicketCreateSchema}
            initialValues={{
              title: "",
              label: "general",
              content: "",
              internal: false,
            }}
            onSubmit={(values, { resetForm }) => {
              submitTicket(values);
              resetForm();
            }}
          >
            {({ handleSubmit, errors, touched }) => (
              <>
                <Form className="form form-label-right">
                  <div className="form-group row">
                    <div className="col-12 pb-5">
                      <Field
                        name="title"
                        component={Input}
                        placeholder="Title"
                        label="Title"
                      />
                    </div>
                    <div className="col-12 pb-5">
                      <Select name="label" label="Label">
                        <option value="general">General</option>
                        <option value="payment">Payment</option>
                        <option value="full-refund">Full refund</option>
                        <option value="tax-refund">Partial refund</option>
                        <option value="adjustment">Adjustment</option>
                      </Select>
                    </div>
                    <div className="col-12">
                      <label>
                        <b>Content</b>
                      </label>
                      <label className="float-right d-flex align-items-center">
                        <Field
                          type="checkbox"
                          name="internal"
                          className="mr-2"
                        />
                        Internal message
                      </label>
                      <Field
                        as="textarea"
                        className={`form-control ${
                          errors.content && touched.content ? "is-invalid" : ""
                        }`}
                        name="content"
                        rows="3"
                        placeholder="Type your message"
                      />
                      {errors.content && touched.content && (
                        <div className="invalid-feedback">{errors.content}</div>
                      )}
                    </div>
                  </div>
                </Form>
                <button
                  type="submit"
                  onClick={() => handleSubmit()}
                  className="btn btn-primary btn-elevate"
                  disabled={isSubmitting}
                >
                  Submit
                </button>
              </>
            )}
          </Formik>
        )}
      </div>
      {isSubmitting && (
        <div
          className="w-100 h-100 position-absolute"
          style={{
            opacity: 0.3,
            backgroundColor: "#CCC",
          }}
        />
      )}
    </div>
  );
}

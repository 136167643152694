
import {createSlice} from "@reduxjs/toolkit";

const initialMerchantsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  data: null,
  entities: null,
  merchantForEdit: undefined,
  rate: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const merchantsSlice = createSlice({
  name: "miles/merchants",
  initialState: initialMerchantsState,
  reducers: {
    catchError: (state, action) => {
      state.error = 'Error: ' + (
        action.payload.error.response?.data?.message
          || action.payload.error.clientMessage
          || action.payload.error.message
      );
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    clearMerchant: (state) => {
      state.merchantForEdit = undefined;
    },
    // findMerchants
    merchantsFetched: (state, action) => {
      const { totalCount, data, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.data = data;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // getMerchantById
    merchantFetched: (state, action) => {
      state.actionsLoading = false;
      state.merchantForEdit = action.payload.merchantForEdit;
      state.error = null;
    },
    // getExchangeRate
    rateFetched: (state, action) => {
      state.actionsLoading = false;
      state.rate = action.payload.rate;
      state.error = null;
    }
  }
});

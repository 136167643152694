import axios from "axios";

export const PARTNER_URL = "partners";

export function signUpMerchant(payload) {
    const { email, tel, contact, company, brNumber, accNumber, file, recapResp } = payload
    
    var bodyFormData = new FormData();
    bodyFormData.append('email', email)
    bodyFormData.append('file', file)
    bodyFormData.append('tel', tel)
    bodyFormData.append('contact', contact)
    bodyFormData.append('company', company)
    bodyFormData.append('accNumber', accNumber)
    bodyFormData.append('brNumber', brNumber)
    bodyFormData.append('recapResp', recapResp)

    return axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL}/${PARTNER_URL}/signup`,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data"}
    });
}
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { useHtmlClassService } from "../../../layout";
import { WidgetSpinner } from "../WidgetSpinner"

export function DivitPieStatsWidget({
  className,
  baseColor,
  id,
  title,
  tooltipFormatter,
  tooltipLabel,
}) {  
  const uiService = useHtmlClassService();

  const data = useSelector(
    state => state.reports.customerReport,
    shallowEqual
  );

  const layoutProps = useMemo(() => {
    return {
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
      tooltipFormatter,
      tooltipLabel,
    };
  }, [uiService, tooltipFormatter, tooltipLabel]);

  useEffect(() => {
    const element = document.getElementById(id);

    if (!element || !data) {
      return;
    }

    const v2 = data.reduce((sum, item) => sum += item.V2, 0);
    const v3 = data.reduce((sum, item) => sum += item.V3, 0);
    const v4 = data.reduce((sum, item) => sum += item.V4, 0);
    const v5 = data.reduce((sum, item) => sum += item.V5, 0);
    const series = [v2, v3, v4, v5];
    const labels = [
      "New customer with purchase",
      "New customer without purchase",
      "Returning customer with first purchase",
      "Returning customer with repeated purchase",
    ];
    const colors = [
      "#c93384",
      "#e44f9c",
      "#6993ff",
      "#a1a9ff",
    ];
    const options = getChartOption(series, labels, colors, layoutProps);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [data, layoutProps, id]);
  
  if (!data) return <WidgetSpinner className={className} />
  
  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-body container p-0">
        <div className="card-spacer row no-gutter">
          <div className="col-12 d-flex">
            <div className="d-flex flex-column">
              <span className="text-dark-75 font-weight-bolder font-size-h4">
                { title }
              </span>
            </div>
          </div>
        </div>
        <div
          id={id}
          className="card-rounded-bottom mb-10"
        ></div>
      </div>
    </div>
  );
}

const getChartOption = (series, labels, colors, layoutProps) => {
  const options = {
    series,
    labels,
    colors,
    chart: {
      type: "donut",
      height: 300,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: false,
            },
            value: {
              show: true,
              formatter: (v, w) => {
                const total = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                return `${(v * 100 / total).toFixed(0)}%`;
              }
            },
          },
        },
      },
    },
    legend: {
      show: true,
      markers: {
        width: 10,
        height: 10,
        radius: 10,
      },
      position: "bottom",
      horizontalAlign: "center", 
    },
    itemMargin: {
      horizontal: 12,
      vertical: 0
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: layoutProps.fontFamily,
      },
    },
  };
  return options;
}

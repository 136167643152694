/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { useIntl } from "react-intl";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';

import { actions } from "../../_redux/authRedux";
import { getTwoFactorQR, enableTwoFactor } from "../../_redux/authCrud";
import { getErrorMessage } from "@/utils/Error";

const headerStyle = {
  fontWeight: 800,
  letterSpacing: '5px',
}

const Setup = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const intl = useIntl();
  const { scope } = useSelector(({ auth }) => auth, shallowEqual);

  const [QR, setQR] = useState();
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const [recoveryCodes, setRecoveryCodes] = useState([]);

  const statedQR = location?.state?.QR;
  const isRecovery = !!statedQR;
  const isForcedSetup = scope === "setup";

  useEffect(() => {
    if (isRecovery) {
      setQR(statedQR);
    } else {
      getTwoFactorQR(isForcedSetup)
        .then((data) => {
          setQR(data?.data?.data);
        })
        .catch((e) => {
          if (e.response?.data?.code === 3012) {
            dispatch(actions.twoFactorAuthenticated());
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickDownload = () => {
    const blob = new Blob([recoveryCodes.join('\n')], { type: "text/plain" });
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(blob);
    a.download = "recovery.txt";
    a.click();
  }

  const onClickComplete = () => {
    dispatch(actions.twoFactorAuthenticated());
  }

  const onClickLogout = () => {
    dispatch(actions.logout());
    history.push("/auth/login");
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const Schema = Yup.object().shape({
    token: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const initialValues = {
    token: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      enableTwoFactor(values.token, (isForcedSetup || isRecovery))
        .then((data) => {
          const recoveryData = data?.data?.data;
          setRecoveryCodes(Object.values(recoveryData));
          setLoading(false);
          setVerified(true);
        })
        .catch((e) => {
          setLoading(false);
          setSubmitting(false);
          setStatus(getErrorMessage(e));
        });
    },
  });

  return (
    <div className="login-form login-signin">
      { /* begin::before verifed */ }
      { !verified && 
        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework"
        >
          {formik.status && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}

          {/* begin::Head */}
          <div className="text-center">
            <h3 className="font-size-h1 mb-10" style={headerStyle}>
              Protect your account
            </h3>
            <p className="text-muted font-weight-bold">
              1. Scan below QR code using authenticator app
            </p>
            <p className="text-muted font-weight-bold">
              2. Enter the 6-digit token generated by the app
            </p>
          </div>
          {/* end::Head */}
          { QR && 
            <img className="w-75 d-block m-auto" src={`data:image/jpeg;base64,${QR}`} alt="QR" />
          }

          <div className="form-group fv-plugins-icon-container">
            <TextField
              name="token"
              label="6-digit token"
              className={getInputClasses("token")}
              type="token"
              margin="normal"
              {...formik.getFieldProps("token")}
            />
            {formik.touched.token && formik.errors.token ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.token}</div>
              </div>
            ) : null}
          </div>

          <button
            className="btn btn-block btn-primary font-weight-bold px-9 py-4 my-3"
            type="submit"
          >
            Submit
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
          { !statedQR &&
            <button
              className="btn btn-block btn-secondary font-weight-bold px-9 py-4 my-3"
              onClick={onClickLogout}
              disabled={formik.isSubmitting}
            >
              <span>Login with another account</span>
            </button>
          }
        </form>
      }
      { /* end::before verifed */ }

      { /* begin::after verifed */ }
      { verified && 
        <>
          { /* begin::not come from recovery */ }
          { !isRecovery &&
            <>
              {/* begin::Head */}
                <div className="text-center mb-10">
                <h3 className="font-size-h1 mb-10" style={headerStyle}>
                  Protect your account
                </h3>
                <p className="text-muted font-weight-bold">
                  Keep following recovery codes in a safe place.
                  If your phone got lost or erased, you will need the code to restore your 2-factor authentication.
                </p>
              </div>
              {/* end::Head */}
              <div className="row border px-10 py-5 mb-10">
                { recoveryCodes.map((code) => <div className="col-6">{ code }</div>) }
              </div>
              <button
                className="btn btn-block btn-secondary font-weight-bold px-9 py-4 my-3"
                onClick={onClickDownload}
              >
                Download
              </button>
              { !isForcedSetup &&
                <button
                  className="btn btn-block btn-primary font-weight-bold px-9 py-4 my-3"
                  onClick={onClickComplete}
                >
                  Complete
                </button>
              }
              { isForcedSetup &&
                <button
                  className="btn btn-block btn-primary font-weight-bold px-9 py-4 my-3"
                  onClick={onClickLogout}
                >
                  Back to Login
                </button>
              }
            </>
          }
          { /* end::not come from recovery */ }

          { /* begin::comes from recovery */ }
          { isRecovery &&
            <>
              {/* begin::Head */}
                <div className="text-center mb-10">
                <h3 className="font-size-h1 mb-10" style={headerStyle}>
                  Recovery Success
                </h3>
                <p className="text-muted font-weight-bold">
                  Please go back and login again.
                </p>
              </div>
              {/* end::Head */}
              <button
                className="btn btn-block btn-primary font-weight-bold px-9 py-4 my-3"
                onClick={onClickLogout}
              >
                Back to Login
              </button>
            </>
          }
          { /* end::comes from recovery */ }
        </>
      }
      { /* end::after verifed */ }
    </div>
  );
}

export default Setup;

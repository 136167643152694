import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { merchantsSlice } from "../app/modules/Merchants/_redux/merchants/merchantsSlice";
import { customersSlice } from "../app/modules/Customers/_redux/customers/customersSlice";
import { ordersSlice as payLaterOrdersSlice } from "../app/modules/Orders/_redux/payLaterOrders/ordersSlice";
import { ordersSlice as payNowOrdersSlice } from "../app/modules/Orders/_redux/payNowOrders/ordersSlice";
import { ordersSlice as refundOrdersSlice } from "../app/modules/Orders/_redux/refundOrders/ordersSlice";
import { giftcardsSlice } from "../app/modules/Giftcards/_redux/giftcardsSlice";
import { batchJobsSlice as orderBatchJobsSlice } from "../app/modules/Orders/_redux/batchJobs/batchJobsSlice";
import { merchantsSlice as milesMerchantsSlice } from "../app/modules/Miles/_redux/merchants/merchantsSlice";
import { customersSlice as milesCustomersSlice } from "../app/modules/Miles/_redux/customers/customersSlice";
import { booksSlice as milesBooksSlice } from "../app/modules/Miles/_redux/books/booksSlice";
import { batchJobsSlice as milesBatchJobsSlice } from "../app/modules/Miles/_redux/batchJobs/batchJobsSlice";
import { tokensSlice as milesTokensSlice } from "../app/modules/Miles/_redux/tokens/tokensSlice";
import { campaignsSlice } from "../app/modules/Campaigns/_redux/campaigns/campaignsSlice";
import { missionsSlice } from "../app/modules/Missions/_redux/missions/missionsSlice";
import { ticketsSlice } from "../app/modules/Tickets/_redux/tickets/ticketsSlice";
import { reportsSlice } from "../app/modules/Reports/_redux/reports/reportsSlice";
import { financialsSlice } from "../app/modules/Financials/_redux/financials/financialsSlice";
import { usersSlice } from "../app/modules/Users/_redux/users/usersSlice";
import { signupSlice } from "../app/modules/Signup/_redux/signupSlice";
import { toolsSlice } from "../app/modules/Tools/_redux/tools/toolsSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  merchants: merchantsSlice.reducer,
  customers: customersSlice.reducer,
  payLaterOrders: payLaterOrdersSlice.reducer,
  payNowOrders: payNowOrdersSlice.reducer,
  refundOrders: refundOrdersSlice.reducer,
  orderBatchJobs: orderBatchJobsSlice.reducer,
  milesMerchants: milesMerchantsSlice.reducer,
  milesCustomers: milesCustomersSlice.reducer,
  milesBooks: milesBooksSlice.reducer,
  milesBatchJobs: milesBatchJobsSlice.reducer,
  milesTokens: milesTokensSlice.reducer,
  giftcards: giftcardsSlice.reducer,
  campaigns: campaignsSlice.reducer,
  missions: missionsSlice.reducer,
  tickets: ticketsSlice.reducer,
  tools: toolsSlice.reducer,
  reports: reportsSlice.reducer,
  financials: financialsSlice.reducer,
  users: usersSlice.reducer,
  signup: signupSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}

import React from "react";


const ASSET_URL = process.env.REACT_APP_ASSET_URL

export default function Agreement() {
  let ts = Math.round((new Date()).getTime() / 1000);
  return(
    <iframe src={`${ASSET_URL}/agreements/merchant_agreement.html?t=${ts}`} title="merchant agreement" width="100%" height="100%" style={{ margin: "none", padding: "none", border: "none"}}></iframe>
  );
}
import {createSlice} from "@reduxjs/toolkit";

const initialFinancialsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  data: null,
  entities: null,
  financialForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const financialsSlice = createSlice({
  name: "financial",
  initialState: initialFinancialsState,
  reducers: {
    catchError: (state, action) => {
      state.error = 'Error: ' + (
        action.payload.error.response?.data?.message
          || action.payload.error.clientMessage
          || action.payload.error.message
      );
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getFinancial
    financialFetched: (state, action) => {
      state.actionsLoading = false;
      state.financialForEdit = action.payload.financialForEdit;
      state.error = null;
    },
    // findFinancial
    financialsFetched: (state, action) => {
      const { totalCount, data, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.data = data;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // downloadReport
    financialDownloaded: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
  }
});

/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { ComingSoon } from "./modules/ComingSoon/ComingSoon";
import { actions } from "./modules/Auth/_redux/authRedux";
import SignupPage from "./modules/Signup/SignupPage";

export function Routes() {
  const dispatch = useDispatch();
  const { is2FAReminder, isAuthorized } = useSelector(
    ({ auth }) => ({
      token: auth.token,
      is2FAReminder: auth.twoFactor === "unset",
      isAuthorized:
        auth.user != null &&
        (auth.twoFactor === "authenticated" || auth.twoFactor === "bypass"),
    }),
    shallowEqual
  );

  // logout if not auth
  useEffect(() => {
    if (!isAuthorized) {    
      dispatch(actions.logout());
    }
  }, [dispatch, isAuthorized]);

  return (
    <Switch>
      <Route path="/logout" component={Logout} />

      {/* auth pages when user at `/auth` and not authorized.*/}
      {(is2FAReminder || !isAuthorized) && (
        <Route path="/auth/*" component={AuthPage} />
      )}

      {/* public routes */}
      <Redirect
        from="/verify/resetpwd/:token"
        to="/auth/reset-password/:token"
      />
      <Route path="/merchant/signup" component={SignupPage} />
      <Route path="/coming-soon" component={ComingSoon} />
      <Route path="/error" component={ErrorsPage} />

      {/* logged in */}
      {is2FAReminder || !isAuthorized ? (
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}

import * as requestFromServer from "./reportsCrud";
import { reportsSlice, callTypes } from "./reportsSlice";
import download from "downloadjs";

const { actions } = reportsSlice;

export const throwError = (err) => (dispatch) => {
  dispatch(actions.catchError({ error: { message: err } }));
};

export const fetchCustomerStats = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .getCustomerStats(queryParams)
    .then((response) => {
      const data = response?.data?.data || [];
      dispatch(actions.customerStatsFetched({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find reports";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchSettlementReports = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .findSettlementReports(queryParams)
    .then((response) => {
      const data = response?.data?.data || [];
      const entities = data;
      const totalCount = response?.data?.count;
      dispatch(
        actions.settlementReportsFetched({ totalCount, data, entities })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find reports";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const downloadSettlementReport = (filename) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getSettlementReport(encodeURIComponent(filename))
    .then((response) => {
      const contentType = response.headers["content-type"];
      download(response.data, filename, contentType);
      dispatch(actions.settlementReportDownloaded());
    })
    .catch((error) => {
      error.clientMessage = "Can't find report";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const uploadSettlementReports = (reportInfo) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .uploadSettlementReports(reportInfo)
    .then((response) => {
      const data = response?.data?.data;
      dispatch(actions.settlementReportUploaded({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create report";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchDailyGiftcardReports = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .findDailyGiftcardReports(queryParams)
    .then((response) => {
      const data = response?.data?.data || [];
      const entities = data;
      const totalCount = response?.data?.count;
      dispatch(
        actions.dailyGiftcardReportsFetched({ totalCount, data, entities })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find reports";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const downloadDailyGiftcardReport = (filename) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getDailyGiftcardReport(encodeURIComponent(filename))
    .then((response) => {
      const contentType = response.headers["content-type"];
      download(response.data, filename, contentType);
      dispatch(actions.dailyGiftcardReportDownloaded());
    })
    .catch((error) => {
      error.clientMessage = "Can't find report";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchDailyMilesReports = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .findDailyMilesReports(queryParams)
    .then((response) => {
      const data = response?.data?.data || [];
      const entities = data;
      const totalCount = response?.data?.count;
      dispatch(
        actions.dailyMilesReportsFetched({ totalCount, data, entities })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find reports";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const downloadDailyMilesReport = (filename) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getDailyMilesReport(encodeURIComponent(filename))
    .then((response) => {
      const contentType = response.headers["content-type"];
      download(response.data, filename, contentType);
      dispatch(actions.dailyMilesReportDownloaded());
    })
    .catch((error) => {
      error.clientMessage = "Can't find report";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchWeeklyMilesReports = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .findWeeklyMilesReports(queryParams)
    .then((response) => {
      const data = response?.data?.data || [];
      const entities = data;
      const totalCount = response?.data?.count;
      dispatch(
        actions.weeklyMilesReportsFetched({ totalCount, data, entities })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find reports";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const downloadWeeklyMilesReport = (filename) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getWeeklyMilesReport(encodeURIComponent(filename))
    .then((response) => {
      const contentType = response.headers["content-type"];
      download(response.data, filename, contentType);
      dispatch(actions.weeklyMilesReportDownloaded());
    })
    .catch((error) => {
      error.clientMessage = "Can't find report";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchMonthlyMilesReports = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .findMonthlyMilesReports(queryParams)
    .then((response) => {
      const data = response?.data?.data || [];
      const entities = data;
      const totalCount = response?.data?.count;
      dispatch(
        actions.monthlyMilesReportsFetched({ totalCount, data, entities })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find reports";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const downloadMonthlyMilesReport = (filename) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getMonthlyMilesReport(encodeURIComponent(filename))
    .then((response) => {
      const contentType = response.headers["content-type"];
      download(response.data, filename, contentType);
      dispatch(actions.monthlyMilesReportDownloaded());
    })
    .catch((error) => {
      error.clientMessage = "Can't find report";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchDailyTransReports = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .findDailyTransReports(queryParams)
    .then((response) => {
      const data = response?.data?.data || [];
      const entities = data;
      const totalCount = response?.data?.count;
      dispatch(
        actions.dailyTransReportsFetched({ totalCount, data, entities })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find reports";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const downloadDailyTransReport = (filename) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getDailyTransReport(encodeURIComponent(filename))
    .then((response) => {
      const contentType = response.headers["content-type"];
      download(response.data, filename, contentType);
      dispatch(actions.dailyTransReportDownloaded());
    })
    .catch((error) => {
      error.clientMessage = "Can't find report";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchMonthlyTransReports = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .findMonthlyTransReports(queryParams)
    .then((response) => {
      const data = response?.data?.data || [];
      const entities = data;
      const totalCount = response?.data?.count;
      dispatch(
        actions.monthlyTransReportsFetched({ totalCount, data, entities })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find reports";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const downloadMonthlyTransReport = (filename) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getMonthlyTransReport(encodeURIComponent(filename))
    .then((response) => {
      const contentType = response.headers["content-type"];
      download(response.data, filename, contentType);
      dispatch(actions.monthlyTransReportDownloaded());
    })
    .catch((error) => {
      error.clientMessage = "Can't find report";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchUnallocatedFund = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .findUnallocatedFund(queryParams)
    .then((response) => {
      const data = response?.data?.data || [];
      const entities = data;
      const totalCount = response?.data?.count;
      dispatch(actions.unallocatedFundFetched({ totalCount, data, entities }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find record";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getUnallocatedFund = (transactionID) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .getUnallocatedFund(transactionID)
    .then((response) => {
      const record = response?.data?.data || {};
      dispatch(actions.unallocatedFundRecordFetched({ record }));
    })
    .catch((error) => {
      error.clientMessage = "Failed to load unallocated fund record";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const assignOrder = (transactionID, orderInfo) => (dispatch) => {
  console.log("reportInfo", transactionID, orderInfo);
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .assignUnallocatedFundToOrder(transactionID, orderInfo)
    .then((response) => {
      const record = response?.data?.data || {};
      dispatch(actions.unallocatedFundToOrderAssigned({ record }));
    })
    .catch((error) => {
      console.log(error.response);
      error.clientMessage = `Failed to update record`;
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const refundUnallocated = (transactionID) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .refundUnallocated(transactionID)
    .then((response) => {
      const record = response?.data?.data || {};
      dispatch(actions.unallocatedFundRefunded({ record }));
    })
    .catch((error) => {
      console.log(error.response);
      error.clientMessage = `Failed to update record`;
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

// **********************
// metrics data
export const fetchOrderReport = (
  abbreviation,
  industry,
  fromDate,
  toDate,
  paynow
) => (dispatch) => {
  dispatch(actions.startOrderReport());
  return requestFromServer
    .getOrderReport(abbreviation, industry, fromDate, toDate, paynow)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.orderReportFetched(data));
    });
};

export const fetchCustomerReport = (
  abbreviation,
  industry,
  fromDate,
  toDate,
  paynow
) => (dispatch) => {
  dispatch(actions.startCustomerReport());
  return requestFromServer
    .getCustomerReport(abbreviation, industry, fromDate, toDate, paynow)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.customerReportFetched(data));
    });
};

export const fetchMerchantMilesReport = (
  abbreviation,
  industry,
  fromDate,
  toDate
) => (dispatch) => {
  dispatch(actions.startMerchantMilesReport());
  return requestFromServer
    .getMerchantMilesReport(abbreviation, industry, fromDate, toDate)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.merchantMilesReportFetched(data));
    });
};

export const fetchCustomerMilesReport = (
  abbreviation,
  industry,
  fromDate,
  toDate
) => (dispatch) => {
  dispatch(actions.startCustomerMilesReport());
  return requestFromServer
    .getCustomerMilesReport(abbreviation, industry, fromDate, toDate)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.customerMilesReportFetched(data));
    });
};

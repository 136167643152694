import React, { createContext, useContext, useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import moment from "moment";

const DashboardControlContext = createContext();

export function useDashboardControlContext() {
  return useContext(DashboardControlContext);
}

export function DashboardControlProvider({ children }) {
  const defaultFromDate = moment().subtract(1, "month").format("YYYY-MM-DD");;
  const defaultToDate = moment().format("YYYY-MM-DD");
  const [abbreviation, setAbbreviation] =  useState();
  const [industry, setIndustry] =  useState();
  const [payService, setPayService] = useState();
  const [supportedServices, setSupportedServices] = useState();
  const [fromDate, setFromDate] = useState(defaultFromDate);
  const [toDate, setToDate] = useState(defaultToDate);

  const { scope, user } = useSelector((state) => state.auth, shallowEqual);

  useEffect(() => {
    if (scope === "divitadmin" || scope === "divitagent") {
      setAbbreviation("ALL");
      setIndustry("admin");
      setPayService("paylater");
      setSupportedServices(["paylater", "paynow"])
    } else {
      // aware API using merchantID as abbreviation
      setAbbreviation(user?.partners[0]?.merchantID);
      setIndustry(user?.partners[0]?.businessType || "airline");
      setPayService("");
      setSupportedServices([])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const value = {
    abbreviation,
    industry,
    payService,
    supportedServices,
    fromDate,
    toDate,
    setAbbreviation,
    setIndustry,
    setPayService,
    setSupportedServices,
    setFromDate,
    setToDate
  };

  return <DashboardControlContext.Provider value={value}>{children}</DashboardControlContext.Provider>;
}
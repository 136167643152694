/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { useHtmlClassService } from "../../../layout";
import { WidgetSpinner } from "../WidgetSpinner"

export function DivitTreeMapStatsWidget({
  className,
  baseColor,
  id,
  title,
}) {  
  const uiService = useHtmlClassService();

  const data = useSelector(
    state => state.reports.metaData,
    shallowEqual
  );

  const layoutProps = useMemo(() => {
    return {
      baseColor: baseColor,
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService, baseColor]);

  useEffect(() => {
    const element = document.getElementById(id);

    if (!element || !data) {
      return;
    }

    const morphedData = Object
      .entries(data.destination)
      .map(([x, y]) => ({ x, y }))
      .filter(({x}) => x !== "HKG")
      .sort((a, b) => b.y > a.y ? 1 : -1)
      .slice(0, 10);
    const options = getChartOption(morphedData, layoutProps);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [data, layoutProps, id]);
  
  if (!data) return <WidgetSpinner className={className} />

  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-body container p-0">
        <div className="card-spacer row no-gutter">
          <div className="col-12 d-flex justify-content-between">
            <div className="d-flex flex-column">
              <span className="text-dark-75 font-weight-bolder font-size-h4">
                { title }
              </span>
            </div>
          </div>
        </div>
        <div
          id={id}
          className="card-rounded-bottom px-8"
          style={{ height: "150px" }}
        ></div>
      </div>
    </div>
  );
}

const getChartOption = (data, layoutProps) => {
  const options = {
    series: [
      {
        data: (data && data.length > 0)
          ? data
          : [{ x: "(No data)", y: 1 }]
      }
    ],
    chart: {
      type: "treemap",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: layoutProps.fontFamily,
      },
    },
    colors: [layoutProps.baseColor],
  };
  return options;
}

import { API } from "@/redux/setupAxios";

export const PARTNERS_URL = "partners";

export default function getllPartners() {
  let params = {
    page: 0,
    pageSize: 200,
  };

  return API.get(PARTNERS_URL, {params});
}

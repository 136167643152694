import {createSlice} from "@reduxjs/toolkit";

const initialBatchJobsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  data: null,
  entities: null,
  result: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const batchJobsSlice = createSlice({
  name: "miles/batchJobs",
  initialState: initialBatchJobsState,
  reducers: {
    catchError: (state, action) => {
      state.error = 'Error: ' + (
        action.payload.error.response?.data?.message
          || action.payload.error.clientMessage
          || action.payload.error.message
      );
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
        state.data = null;
        state.entities = null;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // findBatchJobs
    batchJobsFetched: (state, action) => {
      const { totalCount, data, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.data = data;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // downloadBatchJobResult
    batchJobResultDownloaded: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    // createBatchJob
    batchJobCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.batchJob);
    },
    // updateBatchJob
    batchJobUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.data = state.data.map(entity => {
        if (entity.id === action.payload.batchJob.id) {
          return action.payload.batchJob;
        }
        return entity;
      });
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.batchJob.id) {
          return action.payload.batchJob;
        }
        return entity;
      });
    },
  }
});

import { API, PaynowAPI, MilesAPI, ReportingAPI } from "@/redux/setupAxios";
import axios from "axios";
import store from "@/redux/store";

// [TO-DO] change into merchant service when it is ready
export const API_MERCHANTS_URL = "/partners";
export const PAYNOW_MERCHANTS_URL = "/paynow/merchants"
export const MILES_MERCHANTS_URL = "/miles/merchants";
export const MILES_EXCHANGERATE_URL = "/miles/rates"
export const REPORTING_URL = "/reporting";

export function findMerchants(queryParams) {
  let params;

  if (queryParams) {
    const {
      filter,
      pageNumber,
      pageSize,
    } = queryParams;

    params = {
      page: pageNumber - 1,
      pageSize: pageSize,
    };

    Object.entries(filter).forEach(([key, value]) => {
      if (value) {
        params[key] = value;
      }
    });
  }

  return params
    ? API.get(`${API_MERCHANTS_URL}`, { params })
    : API.get(`${API_MERCHANTS_URL}`);
}
export function getMerchantById(merchantID) {
  return API.get(`${API_MERCHANTS_URL}/${merchantID}`);
}
export function createMerchant(payload) {
  const { email, tel, contact, company, brNumber, accNumber, suppressSendingEmail, recapResp } = payload

  const {
    auth: { token: currentToken }
  } = store.getState();

  var bodyFormData = new FormData();
  bodyFormData.append('email', email)
  bodyFormData.append('tel', tel)
  bodyFormData.append('contact', contact)
  bodyFormData.append('company', company)
  bodyFormData.append('accNumber', accNumber)
  bodyFormData.append('brNumber', brNumber)
  bodyFormData.append('suppressSendingEmail', suppressSendingEmail)
  bodyFormData.append('recapResp', recapResp)

  return axios({
    method: "post",
    url: `${process.env.REACT_APP_API_BASE_URL}${API_MERCHANTS_URL}/signup/add`,
    data: bodyFormData,
    headers: {
      "Content-Type": "multipart/form-data",
      "Authorization": `Bearer ${currentToken}`
    }
  });
}
export function updateMerchant(merchantID, params) {
  const {
    auth: { token: currentToken }
  } = store.getState();

  var bodyFormData = new FormData();
  for (let key in params) {
    bodyFormData.append(key, params[key])
  }

  return axios({
    method: "patch",
    url: `${process.env.REACT_APP_API_BASE_URL}${API_MERCHANTS_URL}/${merchantID}`,
    data: bodyFormData,
    headers: {
      "Content-Type": "multipart/form-data",
      "Authorization": `Bearer ${currentToken}`
    }
  });
}

export function getMerchantExchangeRates(merchantID) {
  return MilesAPI.get(`${MILES_EXCHANGERATE_URL}`, { params: { merchantID: merchantID } })
}
export function updateMerchantExchangeRate(merchantID, rateID, rec) {
  if (rateID !== "") {
    return MilesAPI.patch(`${MILES_EXCHANGERATE_URL}/${rateID}`, rec)
  } else {
    rec.merchantID = merchantID
    return MilesAPI.post(`${MILES_EXCHANGERATE_URL}`, rec)
  }
}

export function getMerchantRates(merchantID, service) {
  if (service === "paynow") {
    return PaynowAPI.get(`${PAYNOW_MERCHANTS_URL}/${merchantID}/rates`);
  } else {
    return API.get(`${API_MERCHANTS_URL}/${merchantID}/rates`);
  }
}
export function updateMerchantRate(merchantID, service, rateID, rec) {
  if (service === "paynow") {
    if (rateID !== "") {
      return PaynowAPI.patch(`${PAYNOW_MERCHANTS_URL}/${merchantID}/rates/${rateID}`, rec)
    } else {
      rec.merchantID = merchantID
      return PaynowAPI.post(`${PAYNOW_MERCHANTS_URL}/${merchantID}/rates`, rec)
    }
  }
}

export function getMerchantRedirectUrls(merchantID) {
  return API.get(`${API_MERCHANTS_URL}/${merchantID}/redirecturls`);
}
export function addMerchantRedirectUrl(merchantID, url) {
  return API.post(`${API_MERCHANTS_URL}/${merchantID}/redirecturls`, url)
}
export function removeMerchantRedirectUrl(merchantID, url) {
  return API.delete(`${API_MERCHANTS_URL}/${merchantID}/redirecturls`, { data: url})
}

export function getMerchantEventSignature(merchantID) {
  return API.get(`${API_MERCHANTS_URL}/${merchantID}/signatures`);
}
export function updateMerchantEventSignature(merchantID, rec) {
  if (rec && rec.signatureKey !== "") {
    return API.post(`${API_MERCHANTS_URL}/${merchantID}/signatures`, rec)
  }
}

export function getMerchantAPIKeys(merchantID) {
  return API.get(`${API_MERCHANTS_URL}/${merchantID}/keys`);
}
export function updateMerchantAPIKey(merchantID, keyID, rec) {
  if (keyID !== "") {
    return API.patch(`${API_MERCHANTS_URL}/${merchantID}/keys/${keyID}`, rec)
  } else {
    rec.merchantID = merchantID
    return API.post(`${API_MERCHANTS_URL}/${merchantID}/keys`, rec)
  }
}

export function getMerchantShopifyConfigs(merchantID) {
  return API.get(`${API_MERCHANTS_URL}/${merchantID}/shopify`);
}
export function upsertMerchantShopifyConfig(merchantID, shopDomain, accessToken, metadata) {
  return API.post(`${API_MERCHANTS_URL}/${merchantID}/shopify`, {
    shopDomain,
    accessToken,
    metadata
  })
}
export function deleteMerchantShopifyConfig(merchantID, shopDomain) {
  return API.delete(`${API_MERCHANTS_URL}/${merchantID}/shopify`, {
    data: { shopDomain }
  })
}

export function getMerchantDoc(merchantID, filename) {
  return API.get(`${API_MERCHANTS_URL}/${merchantID}/document?filename=${filename}`, { responseType: 'blob' });
}

export function getMerchantCustomerData(merchantID) {
  return ReportingAPI.get(`${REPORTING_URL}/merchants/${merchantID}/customers`, { responseType: 'blob' });
}

export function findTokens(merchantID, queryParams) {
  let params;

  if (queryParams) {
    const {
      filter,
      pageNumber,
      pageSize,
    } = queryParams;

    params = {
      page: pageNumber - 1,
      pageSize: pageSize,
    };

    Object.entries(filter).forEach(([key, value]) => {
      if (value) {
        params[key] = value;
      }
    });
  }

  return params
    ? MilesAPI.get(`${MILES_MERCHANTS_URL}/${merchantID}/issue/result`, { params })
    : MilesAPI.get(`${MILES_MERCHANTS_URL}/${merchantID}/issue/result`);
}

import { API } from "@/redux/setupAxios";

export const TICKETS_URL = "ticket";
export const PAYNOW_TICKETS_URL = "/paynow/tickets"
export const GIFTCARD_TICKETS_URL = "/giftcard/tickets"

export function createTicket(service, ticket) {
  const path = service === "paynow" ? PAYNOW_TICKETS_URL : service === "giftcard" ? GIFTCARD_TICKETS_URL : TICKETS_URL
  return API.post(path, ticket)
}

export function createComment(service, ticketId, comment) {
  const path = service === "paynow" ? PAYNOW_TICKETS_URL : service === "giftcard" ? GIFTCARD_TICKETS_URL : TICKETS_URL
  return API.post(`${path}/comments/${ticketId}`, comment)
}

export function getTicket(service, ticketId) {
  const path = service === "paynow" ? PAYNOW_TICKETS_URL : service === "giftcard" ? GIFTCARD_TICKETS_URL : TICKETS_URL
  return API.get(`${path}/${ticketId}`)
}

export function listTickets(service, orderId) {
  const path = service === "paynow" ? PAYNOW_TICKETS_URL : service === "giftcard" ? GIFTCARD_TICKETS_URL : TICKETS_URL
  if (service !== "paynow" && service !== "giftcard") {
    return API.get(`${path}/order/${orderId}`)
  }
  return API.get(`${path}/orders/${orderId}`)
}

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { select, put, takeLatest } from "redux-saga/effects";
import jwt_decode from "jwt-decode";
import { getUserByToken } from "./authCrud";
import { findMerchants } from "../../Miles/_redux/merchants/merchantsCrud";

export const actionTypes = {
  Login: "[Login] Action",
  TwoFactor: "[TwoFactor] Action",
  TwoFactorLater: "[TwoFactorLater] Action",
  TwoFactorAuthenticated: "[TwoFactorAuthenticated] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  Refresh: "[Refresh] Action",
  UpdatePid: "[Update PID]",
};

const initialAuthState = {
  user: undefined,
  token: undefined,
  expire: undefined,
  scope: undefined,
  pid: undefined,
  twoFactor: undefined, // "unset", "bypass", "unauthenticated", "authenticated",
};

const checkScope = (scope) => {
  if (!['partneradmin', 'partneragent', 'divitadmin', 'divitagent', '2fa', 'setup'].includes(scope)) {
    throw new Error('Invalid scope');
  }
}

export const reducer = persistReducer(
  { storage, key: "divit-persist", whitelist: Object.keys(initialAuthState) },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { token, expire } = action.payload;
        const { scope, pid } = jwt_decode(token);
        console.log("scope", scope)
        const twoFactor = scope === "2fa" ? "unauthenticated" : "unset";
        checkScope(scope);
        return { token, expire, scope, pid, user: undefined, twoFactor };
      }

      case actionTypes.TwoFactorLater: {
        return { ...state, twoFactor: "bypass" };
      }

      case actionTypes.TwoFactorAuthenticated: {
        return { ...state, twoFactor: "authenticated" };
      }

      case actionTypes.Register: {
        const { token, expire } = action.payload;
        const { scope, pid } = jwt_decode(token);
        const twoFactor = scope === "2fa" ? "unauthenticated" : "unset";
        checkScope(scope);
        return { token, expire, scope, pid, user: undefined, twoFactor };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.Refresh: {
        const { token, expire } = action.payload;
        const { scope } = jwt_decode(token)
        checkScope(scope);
        return { ...state, token, expire, scope };
      }

      case actionTypes.UpdatePid: {
        const { pid } = action.payload;
        return { ...state, pid };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (token, expire) => ({
    type: actionTypes.Login,
    payload: { token, expire },
  }),
  twoFactor: () => ({
    type: actionTypes.TwoFactor,
  }),
  twoFactorLater: () => ({
    type: actionTypes.TwoFactorLater,
  }),
  twoFactorAuthenticated: () => ({
    type: actionTypes.TwoFactorAuthenticated,
  }),
  register: (token, expire) => ({
    type: actionTypes.Register,
    payload: { token, expire },
  }),
  logout: () => ({
    type: actionTypes.Logout,
  }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({
    type: actionTypes.UserLoaded,
    payload: { user },
  }),
  setUser: (user) => ({
    type: actionTypes.SetUser,
    payload: { user },
  }),
  refresh: (token, expire) => ({
    type: actionTypes.Refresh,
    payload: { token, expire },
  }),
  updatePid: (pid) => ({
    type: actionTypes.UpdatePid,
    payload: { pid },
  })
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    // yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.TwoFactorLater, function* twoFactorSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.TwoFactorAuthenticated, function* twoFactorSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    // yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const userResponse = yield getUserByToken();
    const user = userResponse.data.data;
    yield put(actions.fulfillUser(user));

    const { scope } = yield select(state => state.auth);
    if (scope === "divitadmin" || scope === "divitagent") {
      const merchantResponse = yield findMerchants();
      const merchants = merchantResponse.data.data;
      const divit = merchants.find(({ abbreviation }) => abbreviation === "DIVIT");
      yield put(actions.updatePid(divit.merchantID));
    }
  });
}

import moment from "moment";
import axios from "axios";
import axiosThrottle from "axios-request-throttle";

import store from "./store";
import { actions } from "../app/modules/Auth/_redux/authRedux";

let refreshingToken = false;
let retryQueue = [];

axiosThrottle.use(axios, { requestsPerSecond: 5 });

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const PAYNOW_BASE_URL =
  process.env.REACT_APP_PAYNOW_SERVICE_BASE_URL || API_BASE_URL;
const CAMPAIGN_BASE_URL =
  process.env.REACT_APP_CAMPAIGN_SERVICE_BASE_URL || API_BASE_URL;
const GIFTCARD_BASE_URL =
  process.env.REACT_APP_GIFTCARD_SERVICE_BASE_URL || API_BASE_URL;
const MILES_BASE_URL =
  process.env.REACT_APP_MILES_SERVICE_BASE_URL || API_BASE_URL;
const REPORTING_BASE_URL =
  process.env.REACT_APP_REPORTING_SERVICE_BASE_URL || API_BASE_URL;
const PAYMENT_BASE_URL =
  process.env.REACT_APP_PAYMENT_SERVICE_BASE_URL || API_BASE_URL;

const getAxiosInstance = (baseURL) => {
  if (baseURL === API_BASE_URL) {
    return API;
  }
  return axios;
};

const processQueue = (error, token = null) => {
  retryQueue.forEach((task) => {
    if (error) {
      task.reject(error);
    } else {
      task.resolve(token);
    }
  });
  retryQueue = [];
};

const forceLogout = () => {
  retryQueue = [];
  refreshingToken = false;
  store.dispatch(actions.logout());
};

const refreshToken = async () => {
  try {
    refreshingToken = true;
    const {
      auth: { token: currentToken },
    } = store.getState();

    const RefreshInstance = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      headers: { Authorization: `Bearer ${currentToken}` },
    });
    const { data } = await RefreshInstance.get("auth/refresh");
    const { token, expire } = data;
    store.dispatch(actions.refresh(token, expire));
    refreshingToken = false;
    return token;
  } catch (err) {
    refreshingToken = false;
    // expired token
    if (err?.response?.status === 401) {
      forceLogout();
    }
    return Promise.reject(err);
  }
};

const requestFulfilledHandler = async (config) => {
  const {
    auth: { token, expire },
  } = store.getState();

  if (refreshingToken) {
    return new Promise((resolve, reject) => {
      retryQueue.push({ resolve, reject });
    })
      .then((token) => {
        config.headers.Authorization = `Bearer ${token}`;
        return Promise.resolve(config);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          forceLogout();
        }
        return Promise.reject(err);
      });
  }

  if (token) {
    if (moment().isAfter(moment(expire))) {
      const newToken = await refreshToken();
      config.headers.Authorization = `Bearer ${newToken}`;
      processQueue(null, newToken);
    } else if (!config.headers.Authorization) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }

  return config;
};

const requestRejectedHandler = (err) => Promise.reject(err);

const responseFufilledHandler = (response) => response;

const responseRejectedHandler = async (err) => {
  // error without response (e.g. network error)
  if (!err.response) {
    return Promise.reject(err);
  }

  if (!err.config) {
    return Promise.reject(err);
  }

  const originalRequest = err.config;
  const axiosInstance = getAxiosInstance(originalRequest.baseURL);

  // no retry if it is a login request
  if (err.response.status === 401 && originalRequest.url === "login") {
    return Promise.reject(err);
  }

  // no retry if it is a login request
  if (err.response.status === 401 && err.response.data.code === 40000) {
    return Promise.reject(err);
  }

  // unauthorized request
  if (err.response.status === 401 && !originalRequest._retry) {
    // there is another unauthorized request trying to refresh token,
    // put current request into queue
    if (refreshingToken) {
      return new Promise((resolve, reject) => {
        retryQueue.push({ resolve, reject });
      })
        .then((token) => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return axiosInstance(originalRequest);
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            forceLogout();
          }
          return Promise.reject(err);
        });
    }

    // current request is the first one encountering unauthorized
    try {
      const newToken = await refreshToken();
      // setup new token and process queuing requests
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${newToken}`;
      processQueue(null, newToken);
      // retry current request
      originalRequest.headers.Authorization = `Bearer ${newToken}`;
      originalRequest._retry = true;
      return axiosInstance(originalRequest);
    } catch (err) {
      processQueue(err, null);
      return Promise.reject(err);
    }
  }

  if (err.response.status === 401 && originalRequest._retry) {
    forceLogout();
  }
  // other server errors, or have already re-tried unauthorized request
  return Promise.reject(err);
};

const API = axios.create({
  baseURL: API_BASE_URL,
});
API.interceptors.request.use(requestFulfilledHandler, requestRejectedHandler);
API.interceptors.response.use(responseFufilledHandler, responseRejectedHandler);

const PaynowAPI = axios.create({
  baseURL: PAYNOW_BASE_URL,
});
PaynowAPI.interceptors.request.use(
  requestFulfilledHandler,
  requestRejectedHandler
);
PaynowAPI.interceptors.response.use(
  responseFufilledHandler,
  responseRejectedHandler
);

const CampaignAPI = axios.create({
  baseURL: CAMPAIGN_BASE_URL,
});
CampaignAPI.interceptors.request.use(
  requestFulfilledHandler,
  requestRejectedHandler
);
CampaignAPI.interceptors.response.use(
  responseFufilledHandler,
  responseRejectedHandler
);

const GiftcardAPI = axios.create({
  baseURL: GIFTCARD_BASE_URL,
});
GiftcardAPI.interceptors.request.use(
  requestFulfilledHandler,
  requestRejectedHandler
);
GiftcardAPI.interceptors.response.use(
  responseFufilledHandler,
  responseRejectedHandler
);

const MilesAPI = axios.create({
  baseURL: MILES_BASE_URL,
});
MilesAPI.interceptors.request.use(
  requestFulfilledHandler,
  requestRejectedHandler
);
MilesAPI.interceptors.response.use(
  responseFufilledHandler,
  responseRejectedHandler
);

const ReportingAPI = axios.create({
  baseURL: REPORTING_BASE_URL,
});
ReportingAPI.interceptors.request.use(
  requestFulfilledHandler,
  requestRejectedHandler
);
ReportingAPI.interceptors.response.use(
  responseFufilledHandler,
  responseRejectedHandler
);

const PaymentAPI = axios.create({
  baseURL: PAYMENT_BASE_URL,
});
PaymentAPI.interceptors.request.use(
  requestFulfilledHandler,
  requestRejectedHandler
);
PaymentAPI.interceptors.response.use(
  responseFufilledHandler,
  responseRejectedHandler
);

export {
  API,
  PaynowAPI,
  CampaignAPI,
  GiftcardAPI,
  MilesAPI,
  ReportingAPI,
  PaymentAPI,
  refreshToken,
};

import * as requestFromServer from "./signupCrud";
import { signupSlice, callTypes } from "./signupSlice";

const { actions } = signupSlice;

export const signup = (payload) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
      .signUpMerchant(payload)
      .then((response) => {
        const data = response.data
        dispatch(actions.signupSuccess({ data }));
      })
      .catch((error) => {
        error.clientMessage = "signup failed";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
  };
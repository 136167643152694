import { API, ReportingAPI, PaymentAPI } from "@/redux/setupAxios";
import axios from "axios";
import store from "@/redux/store";

export const REPORTS_URL = "reports";
export const REPORTING_URL = "reporting";
export const PAYMENT_URL = "payment";

// CREATE =>  POST: add a new report to the server
// export function uploadSettlementReport(reports) {
//   return API.post(REPORTS_URL, { reports });
// }

export function uploadSettlementReports(params) {
  const {
    auth: { token: currentToken },
  } = store.getState();

  const bodyFormData = new FormData();
  for (let key in params) {
    bodyFormData.append(key, params[key]);
  }

  return axios({
    method: "post",
    url: `${process.env.REACT_APP_REPORTING_SERVICE_BASE_URL}/reporting/settlement/report`,
    data: bodyFormData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${currentToken}`,
    },
  });
}

export function getSettlementReport(filename) {
  return ReportingAPI.get(`${REPORTING_URL}/settlement/report/${filename}`, {
    responseType: "blob",
  });
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findSettlementReports(queryParams) {
  const { filter, pageNumber, pageSize } = queryParams;

  let params = {
    page: pageNumber - 1,
    pageSize: pageSize,
  };

  Object.entries(filter).forEach(([key, value]) => {
    if (value) {
      params[key] = value;
    }
  });

  return ReportingAPI.get(`${REPORTING_URL}/settlement/report`, { params });
}

export function getCustomerStats(params) {
  return ReportingAPI.get(`${REPORTING_URL}/stats/customers`, { params });
}

export function getDailyGiftcardReport(filename) {
  return ReportingAPI.get(`${REPORTING_URL}/giftcard/daily/${filename}`, {
    responseType: "blob",
  });
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findDailyGiftcardReports(queryParams) {
  const { filter, pageNumber, pageSize } = queryParams;

  let params = {
    page: pageNumber - 1,
    pageSize: pageSize,
  };

  Object.entries(filter).forEach(([key, value]) => {
    if (value) {
      params[key] = value;
    }
  });

  return ReportingAPI.get(`${REPORTING_URL}/giftcard/daily`, { params });
}

export function getDailyMilesReport(filename) {
  return ReportingAPI.get(`${REPORTING_URL}/miles/daily/${filename}`, {
    responseType: "blob",
  });
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findDailyMilesReports(queryParams) {
  const { filter, pageNumber, pageSize } = queryParams;

  let params = {
    page: pageNumber - 1,
    pageSize: pageSize,
  };

  Object.entries(filter).forEach(([key, value]) => {
    if (value) {
      params[key] = value;
    }
  });

  return ReportingAPI.get(`${REPORTING_URL}/miles/daily`, { params });
}

export function getWeeklyMilesReport(filename) {
  return ReportingAPI.get(`${REPORTING_URL}/miles/weekly/${filename}`, {
    responseType: "blob",
  });
}

export function getMonthlyMilesReport(filename) {
  return ReportingAPI.get(`${REPORTING_URL}/miles/monthly/${filename}`, {
    responseType: "blob",
  });
}

export function getDailyTransReport(filename) {
  return ReportingAPI.get(`${REPORTING_URL}/paynow/daily/report/${filename}`, {
    responseType: "blob",
  });
}

export function getMonthlyTransReport(filename) {
  return ReportingAPI.get(
    `${REPORTING_URL}/paynow/monthly/report/${filename}`,
    {
      responseType: "blob",
    }
  );
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findMonthlyMilesReports(queryParams) {
  const { filter, pageNumber, pageSize } = queryParams;

  let params = {
    page: pageNumber - 1,
    pageSize: pageSize,
  };

  Object.entries(filter).forEach(([key, value]) => {
    if (value) {
      params[key] = value;
    }
  });

  return ReportingAPI.get(`${REPORTING_URL}/miles/monthly`, { params });
}

export function findWeeklyMilesReports(queryParams) {
  const { filter, pageNumber, pageSize } = queryParams;

  let params = {
    page: pageNumber - 1,
    pageSize: pageSize,
  };

  Object.entries(filter).forEach(([key, value]) => {
    if (value) {
      params[key] = value;
    }
  });

  return ReportingAPI.get(`${REPORTING_URL}/miles/weekly`, { params });
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findDailyTransReports(queryParams) {
  const { filter, pageNumber, pageSize } = queryParams;

  let params = {
    page: pageNumber - 1,
    pageSize: pageSize,
  };

  Object.entries(filter).forEach(([key, value]) => {
    if (value) {
      params[key] = value;
    }
  });

  return ReportingAPI.get(`${REPORTING_URL}/paynow/daily/report`, { params });
}

export function findMonthlyTransReports(queryParams) {
  const { filter, pageNumber, pageSize } = queryParams;

  let params = {
    page: pageNumber - 1,
    pageSize: pageSize,
  };

  Object.entries(filter).forEach(([key, value]) => {
    if (value) {
      params[key] = value;
    }
  });

  return ReportingAPI.get(`${REPORTING_URL}/paynow/monthly/report`, { params });
}

export function findUnallocatedFund(queryParams) {
  const { filter, pageNumber, pageSize } = queryParams;

  let params = {
    page: pageNumber - 1,
    pageSize: pageSize,
  };

  Object.entries(filter).forEach(([key, value]) => {
    if (value) {
      params[key] = value;
    }
  });

  return PaymentAPI.get(`${PAYMENT_URL}/unallocated`, { params });
}

export function getUnallocatedFund(transactionID) {
  return PaymentAPI.get(`${PAYMENT_URL}/unallocated/${transactionID}`);
}

export function assignUnallocatedFundToOrder(transactionID, payload) {
  return PaymentAPI.post(
    `${PAYMENT_URL}/unallocated/${transactionID}/assign`,
    payload
  );
}

export function refundUnallocated(transactionID) {
  return PaymentAPI.post(`${PAYMENT_URL}/unallocated/${transactionID}/refund`);
}

export function getOrderReport(
  abbreviation,
  industry,
  fromDate,
  toDate,
  paynow
) {
  const params = {
    // aware API using merchantID
    merchantID: abbreviation === "ALL" ? null : abbreviation,
    // no data return if industry is admin
    industry: industry === "admin" ? undefined : industry,
    entity: "order",
    fromDate,
    toDate,
  };
  if (paynow) {
    return API.get(`paynow/${REPORTS_URL}/performance`, { params });
  }
  return API.get(`${REPORTS_URL}/performance`, { params });
}

export function getCustomerReport(
  abbreviation,
  industry,
  fromDate,
  toDate,
  paynow
) {
  const params = {
    // aware API using merchantID
    merchantID: abbreviation === "ALL" ? null : abbreviation,
    // no data return if industry is admin
    industry: industry === "admin" ? undefined : industry,
    entity: "customer",
    fromDate,
    toDate,
  };
  if (paynow) {
    return API.get(`paynow/${REPORTS_URL}/performance`, { params });
  }
  return API.get(`${REPORTS_URL}/performance`, { params });
}

export function getMerchantMilesReport(
  abbreviation,
  industry,
  fromDate,
  toDate
) {
  const params = {
    abbreviation: abbreviation === "ALL" ? null : abbreviation,
    industry: abbreviation === "ALL" ? null : industry,
    entity: "merchant_miles",
    fromDate,
    toDate,
  };
  return API.get(`miles/${REPORTS_URL}/performance`, { params });
}

export function getCustomerMilesReport(
  abbreviation,
  industry,
  fromDate,
  toDate
) {
  const params = {
    abbreviation: abbreviation === "ALL" ? null : abbreviation,
    industry: abbreviation === "ALL" ? null : industry,
    entity: "customer_miles",
    fromDate,
    toDate,
  };
  return API.get(`miles/${REPORTS_URL}/performance`, { params });
}

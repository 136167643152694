import { API } from "@/redux/setupAxios";

export const LOGIN_URL = "login";
export const LOGOUT_URL = "/auth/logout";
export const CHANGE_PASSWORD_URL = "/profiles/pwd";
export const REQUEST_PASSWORD_URL = "/verify/resetpwd/send/partner"; 
export const RESET_PASSWORD_URL = "/profiles/resetpwd";
export const TWO_FACTOR_FORCED_SETUP_URL = "/setup/2fa";
export const TWO_FACTOR_NORMAL_SETUP_URL = "/profiles/setup/2fa";
export const TWO_FACTOR_TRANSFER_URL = "/profiles/setup/2fa/migrate";
export const TWO_FACTOR_GENERATE_RECOVERY_URL = "/profiles/setup/2fa/gencodes";
export const TWO_FACTOR_AUTH_URL = "/2fa";

// to be removed
export const PROFILE_URL = "";
export const REGISTER_URL = "";

export const ME_URL = "profiles";

export function login(email, password, recapResp) {
  const formData = new FormData();
  formData.append('client_id', email);
  formData.append('client_secret', password);
  formData.append('recapResp', recapResp)
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  return API.post(LOGIN_URL, formData, config);
}

export function logout(token) {
  return API.post(LOGOUT_URL, { token });
}

export function changePassword(oldPwd, newPwd) {
  return API.patch(CHANGE_PASSWORD_URL, { oldPwd, newPwd });
}

export function register(email, password) {
  return new Error("Not implemented");
}

export function patchProfile(firstName, lastName) {
  return new Error("Not implemented");
}

export function requestPassword(email, recapResp) {
  return API.post(REQUEST_PASSWORD_URL, { email, recapResp });
}

export function resetPassword(token, password) {
  return API.patch(RESET_PASSWORD_URL, { token, newPwd: password });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return API.get(ME_URL);
}

export function getTwoFactorQR(forced = false) {
  return forced
    ? API.get(TWO_FACTOR_FORCED_SETUP_URL)
    : API.get(TWO_FACTOR_NORMAL_SETUP_URL);
}

export function getTransferTwoFactorQR() {
  return API.post(TWO_FACTOR_TRANSFER_URL);
}

export function enableTwoFactor(token, forced = false) {
  return forced
    ? API.patch(TWO_FACTOR_FORCED_SETUP_URL, { token })
    : API.patch(TWO_FACTOR_NORMAL_SETUP_URL, {
      token,
      enabled: true,
    });
}

export function disableTwoFactor(token, forced = false) {
  return forced
    ? API.patch(TWO_FACTOR_FORCED_SETUP_URL, { token })
    : API.patch(TWO_FACTOR_NORMAL_SETUP_URL, {
      token,
      enabled: false,
    });
}

export function generateRecoveryCodes() {
  return API.post(TWO_FACTOR_GENERATE_RECOVERY_URL);
}

export function verifyTwoFactorToken(token) {
  return API.post(TWO_FACTOR_AUTH_URL, { token });
}

export function recoverTwoFactor(token) {
  return API.patch(TWO_FACTOR_AUTH_URL, { token });
}

import {createSlice} from "@reduxjs/toolkit";

const initialSignupState = {
  actionsLoading: false,
  completed: false,
  error: null,
  errorData: null,
};
export const callTypes = {
  action: "action"
};

export const signupSlice = createSlice({
  name: "signup",
  initialState: initialSignupState,
  reducers: {
    catchError: (state, action) => {
      state.error = 'Error: ' + (
        action.payload.error.response?.data?.message
          || action.payload.error.clientMessage
          || action.payload.error.message
      );
      if (action.payload.error.response?.data?.errorData) {
        state.errorData = action.payload.error.response?.data?.errorData
      }
      state.actionsLoading = false;
      state.completed = false;
    },
    startCall: (state, action) => {
      state.actionsLoading = true;
      state.error = null;
      state.errorData = null;
      state.completed = false;
    },
    signupSuccess: (state, action) => {
        state.actionsLoading = false;
        state.error = null;
        state.errorData = null;
        state.completed = true;
    },
  }
});
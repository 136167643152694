import { MilesAPI } from "@/redux/setupAxios";

export const MILES_MERCHANTS_URL = "miles/merchants";
export const MILES_URL = "miles";

export function findMerchants(queryParams) {
  let params;

  if (queryParams) {
    const {
      filter,
      pageNumber,
      pageSize,
    } = queryParams;

    params = {
      page: pageNumber - 1,
      pageSize: pageSize,
    };

    Object.entries(filter).forEach(([key, value]) => {
      if (value) {
        params[key] = value;
      }
    });
  }

  return params
    ? MilesAPI.get(`${MILES_MERCHANTS_URL}`, { params })
    : MilesAPI.get(`${MILES_MERCHANTS_URL}`);
}

export function getMerchantById(merchantId) {
  return MilesAPI.get(`${MILES_MERCHANTS_URL}/${merchantId}`);
}

export function getExchangeRate(baseCurrency, currency) {
  return MilesAPI.get(`${MILES_URL}/rates/merchants/${baseCurrency}/${currency}`)
}

export function findTokens(merchantId, queryParams) {
  let params;

  if (queryParams) {
    const {
      filter,
      pageNumber,
      pageSize,
    } = queryParams;

    params = {
      page: pageNumber - 1,
      pageSize: pageSize,
    };

    Object.entries(filter).forEach(([key, value]) => {
      if (value) {
        if (key === "status") {
          if (value === "claimed") {
            params["claimed"] = true;
          } else if (value === "notClaim") {
            params["claimed"] = false;
          }
        } else {
          params[key] = value;
        }
      }
    });
  }

  return params
    ? MilesAPI.get(`${MILES_MERCHANTS_URL}/${merchantId}/issue/result`, { params })
    : MilesAPI.get(`${MILES_MERCHANTS_URL}/${merchantId}/issue/result`);
}
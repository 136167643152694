import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {
  DashboardControlWidget,
  DivitBarStatsWidget,
  DivitCombinedBarsStatsWidget,
  DivitHistogramStatsWidget,
  DivitLineStatsWidget,
  DivitStackedBarsStatsWidget,
  DivitTreeMapStatsWidget,
  DivitPieStatsWidget,
  DivitCustomerStatsWidget,
} from "../widgets";
import * as reportsActions from "../../../app/modules/Reports/_redux/reports/reportsActions";
import { useDashboardControlContext } from "../widgets/controls/DashboardControlProvider";
import { FormattedPrice, Round } from "../../../utils/Price";

export const DivitDemo1Dashboard = () => {
  const dispatch = useDispatch();
  const { scope, expire } = useSelector((state) => state.auth, shallowEqual);
  const { abbreviation, industry, payService, supportedServices, fromDate, toDate } = useDashboardControlContext();

  useEffect(() => {
    if (!moment(expire).isAfter(moment(Date.now()))) {
      return
    }
    if (scope === "divitadmin" || scope === "divitagent") {
      dispatch(reportsActions.fetchCustomerStats({
        fromDate,
        toDate: moment(toDate).add(1, "day").format("YYYY-MM-DD"),
      }));
    }
    if (industry === "admin" || industry === "airline" || industry === "retail") {
      if (payService === "paylater" && supportedServices.length > 0) {
        dispatch(reportsActions.fetchOrderReport(
          abbreviation,
          industry,
          fromDate,
          moment(toDate).add(1, "day").format("YYYY-MM-DD"),
          false,
        ));
        dispatch(reportsActions.fetchCustomerReport(
          abbreviation,
          industry,
          fromDate,
          moment(toDate).add(1, "day").format("YYYY-MM-DD"),
          false,
        ));
      }
      if (payService === "paynow" && supportedServices.length > 0) {
        dispatch(reportsActions.fetchOrderReport(
          abbreviation,
          industry,
          fromDate,
          moment(toDate).add(1, "day").format("YYYY-MM-DD"),
          true,
        ));
        dispatch(reportsActions.fetchCustomerReport(
          abbreviation,
          industry,
          fromDate,
          moment(toDate).add(1, "day").format("YYYY-MM-DD"),
          true,
        ));
      }
    }
    if ((scope === "divitadmin" || scope === "partneradmin")
      && (industry === "admin" || industry === "insurance" || industry === "retail")
    ) {
      dispatch(reportsActions.fetchMerchantMilesReport(
        abbreviation,
        industry,
        fromDate,
        moment(toDate).add(1, "day").format("YYYY-MM-DD"),
      ));
    }
    if (scope === "divitadmin" 
      && industry === "admin"
    ) {
      dispatch(reportsActions.fetchCustomerMilesReport(
        abbreviation,
        industry,
        fromDate,
        moment(toDate).add(1, "day").format("YYYY-MM-DD"),
      ));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abbreviation, industry, payService, fromDate, toDate, expire]);

  return (
    <>
      <div className="row">
        <div className="col-lg-6 col-xxl-4">
          <DashboardControlWidget
            className="card-stretch gutter-b"
          />
        </div> 
        {
          (scope === "divitadmin" || scope === "divitagent") &&
          <div className="col-lg-6 col-xxl-8">
             <DivitCustomerStatsWidget
                className="card-stretch gutter-b"
                symbolShape="circle"
                baseColor="primary"
                id="divit_customer_stats_widget_chart"
                timeAttribute="statsDate"
                valueAttribute="numOfCustomer"
                title="Customer Signup"
                tooltipLabel="Count"
              />
          </div>
        }
        { (industry === "admin" || industry === "airline" || industry === "retail") &&
          <div className="col-lg-12 col-xxl-12">
            <DivitCombinedBarsStatsWidget
              className="card-stretch gutter-b"
              symbolShape="circle"
              baseColor="primary"
              id="orders_customers_stats_widget_chart"
              timeAttribute="LogDate"
              valueAttributeA="V0"
              valueAttributeB="V1"
              titleA="Number of orders"
              titleB="Number of customers"
              tooltipLabelA="Number of orders"
              tooltipLabelB="Number of customers"
              tooltipFormatter={(value) => value}
            />
          </div>
        }
        { (scope === "divitadmin" || scope === "partneradmin") &&
          (industry === "admin" || industry === "airline" || industry === "retail") &&
            <div className="col-lg-6 col-xxl-8">
              <DivitHistogramStatsWidget
                className="card-stretch gutter-b"
                baseColor="primary"
                id="order_price_range_widget_chart"
                title="Order price range"
                tooltipFormatter={(value) => value}
                tooltipLabel="Count"
              />
            </div>
        }
        { (scope === "divitadmin" || scope === "partneradmin") &&
          (industry === "admin" || industry === "airline" || industry === "retail") &&
            <div className="col-lg-6 col-xxl-4">
              <DivitLineStatsWidget
                className="card-stretch gutter-b"
                baseColor="primary"
                id="average_order_stats_widget_chart"
                stateName="orderReport"
                timeAttribute="LogDate"
                valueAttribute="V3"
                title="Average order value"
                tooltipFormatter={(value) => FormattedPrice("HKD", Round(value * 100))}
                tooltipLabel="Amount"
              />
            </div>
        }
        { scope === "divitadmin" &&
          (industry === "admin" || industry === "airline" || industry === "retail") &&
            <div className="col-lg-6 col-xxl-4">
              <DivitBarStatsWidget
                className="card-stretch gutter-b"
                symbolShape="circle"
                baseColor="primary"
                id="divit_fee_stats_widget_chart"
                timeAttribute="LogDate"
                valueAttribute="V5"
                title="Total Divit fee"
                tooltipFormatter={(value) => FormattedPrice("HKD", Round(value * 100))}
                tooltipLabel="Amount"
              />
            </div>
        }
        { scope === "divitadmin" && payService === "paylater" && 
          (industry === "admin" || industry === "airline" || industry === "retail") &&
            <div className="col-lg-6 col-xxl-4">
              <DivitBarStatsWidget
                className="card-stretch gutter-b"
                symbolShape="circle"
                baseColor="primary"
                id="late_fee_stats_widget_chart"
                timeAttribute="LogDate"
                valueAttribute="V6"
                title="Total late fee"
                tooltipFormatter={(value) => FormattedPrice("HKD", Round(value * 100))}
                tooltipLabel="Amount"
              />
            </div>
        }
        { (scope === "divitadmin" || scope === "partneradmin") &&
          (industry === "admin" || industry === "airline" || industry === "retail") &&
            <div className="col-lg-6 col-xxl-4">
              <DivitPieStatsWidget
                className="card-stretch gutter-b"
                symbolShape="circle"
                baseColor="primary"
                id="pie_stats_widget_chart"
                title="Customer behaviour"
                tooltipFormatter={(value) => value}
                tooltipLabel="Count"
              />
            </div>
        }
        { (industry === "admin" || industry === "airline") && (payService === "paylater") && 
          <div className="col-lg-6 col-xxl-6">
            <DivitTreeMapStatsWidget
              className="card-stretch gutter-b"
              baseColor="#c93384"
              id="flight_destination_widget_chart"
              title="Top 10 flight destination"
            />
          </div>
        }
        { (scope === "divitadmin" || scope === "partneradmin") &&
          (industry === "admin" || industry === "insurance" || industry === "retail") &&
          <div className="col-lg-6 col-xxl-6">
            <DivitLineStatsWidget
              className="card-stretch gutter-b"
              baseColor="primary"
              id="merchant_miles_balance_widget_chart"
              stateName="merchantMilesReport"
              timeAttribute="logDate"
              valueAttribute="v0"
              title="Merchant miles balance"
              tooltipFormatter={(value) => value}
              tooltipLabel="Balance"
            />
          </div>
        }
        { (scope === "divitadmin" || scope === "partneradmin") &&
          (industry === "admin" || industry === "insurance" || industry === "retail") &&
          <div className="col-lg-6 col-xxl-6">
            <DivitStackedBarsStatsWidget
              className="card-stretch gutter-b"
              baseColor="primary"
              id="merchant_miles_flow_widget_chart"
              title="Merchant miles flow"
              stateName="merchantMilesReport"
              timeAttribute="logDate"
              valueAttributes={[
                "v1",
                "v2",
                "v3",
                "v4",
                "v5",
                "v6",
              ]}
              labels={[
                "Topup",
                "Issued",
                "Claimed",
                "Issued purchase",
                "Issued pending",
                "Burn",
              ]}
              tooltipFormatter={(value) => value}
            />
          </div>
        }
        { scope === "divitadmin" &&
          industry === "admin" &&
          <div className="col-lg-6 col-xxl-6">
            <DivitLineStatsWidget
              className="card-stretch gutter-b"
              baseColor="primary"
              id="customer_miles_balance_widget_chart"
              stateName="customerMilesReport"
              timeAttribute="logDate"
              valueAttribute="v1"
              title="Customer miles balance"
              tooltipFormatter={(value) => value}
              tooltipLabel="Balance"
            />
          </div>
        }
        { scope === "divitadmin" &&
          industry === "admin" &&
          <div className="col-lg-6 col-xxl-6">
            <DivitStackedBarsStatsWidget
              className="card-stretch gutter-b"
              baseColor="primary"
              id="customer_miles_flow_widget_chart"
              title="Customer miles flow"
              stateName="customerMilesReport"
              timeAttribute="logDate"
              valueAttributes={[
                "v2",
                "v3",
                "v4",
                "v5",
                "v6",
                "v7",
                "v8",
              ]}
              labels={[
                "Claimed",
                "Earned",
                "Earn pending",
                "Burn",
                "Converted out",
                "Converted in",
                "Expired",
              ]}
              tooltipFormatter={(value) => value}
            />
          </div>
        }
      </div>
    </>
  );
}

import { createSlice } from "@reduxjs/toolkit";

const initialOrdersState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  data: null,
  entities: null,
  orderForEdit: undefined,
  lastError: null,
  instruments: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const ordersSlice = createSlice({
  name: "payLaterOrders",
  initialState: initialOrdersState,
  reducers: {
    catchError: (state, action) => {
      state.error =
        "Error: " +
        (action.payload.error.response?.data?.message ||
          action.payload.error.clientMessage ||
          action.payload.error.message);
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getInstruments
    instrumentFetched: (state, action) => {
      const { instruments } = action.payload;
      state.actionsLoading = false;
      state.instruments = instruments;
      state.error = null;
    },
    // getOrderById
    orderFetched: (state, action) => {
      state.actionsLoading = false;
      state.orderForEdit = action.payload.orderForEdit;
      state.error = null;
    },
    // findOrders
    ordersFetched: (state, action) => {
      const { totalCount, data, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.data = data;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createOrder
    orderCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.order);
    },
    // updateOrder
    orderUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.data = state.data.map((entity) => {
        if (entity.id === action.payload.order.id) {
          return action.payload.order;
        }
        return entity;
      });
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.order.id) {
          return action.payload.order;
        }
        return entity;
      });
    },
    // deleteOrder
    orderDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.data = state.data.filter((el) => el.id !== action.payload.id);
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
    // deleteOrders
    ordersDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.data = state.data.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },
    // ordersUpdateState
    ordersStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
      state.data = state.data.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
  },
});


import {createSlice} from "@reduxjs/toolkit";

const initialBooksState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  data: null,
  entities: null,
  bookForEdit: undefined,
  transactions: undefined,
  transactionCount: 0,
  lastError: null,
  topUpSuccess: false,
  topUpError: false,
  transferMilesSuccess: false,
  transferMilesError: false,
  updateSuccess: false,
  updateError: false,
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const booksSlice = createSlice({
  name: "miles/miles-books",
  initialState: initialBooksState,
  reducers: {
    catchError: (state, action) => {
      state.error = 'Error: ' + (
        action.payload.error.response?.data?.message
          || action.payload.error.clientMessage
          || action.payload.error.message
      );
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      state.updateSuccess = false;
      state.updateError = false;
      state.topUpSuccess = false;
      state.topUpError = false;
      state.transferMilesSuccess = false;
      state.transferMilesError = false;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    clearBooks: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.data = null;
      state.entities = null;
      state.totalCount = 0;
      state.topUpSuccess = false;
      state.topUpError = false;
    },
    // getBookById
    bookFetched: (state, action) => {
      state.actionsLoading = false;
      state.bookForEdit = action.payload.bookForEdit;
      state.error = null;
    },
    // getTransactionsById
    transactionsFetched: (state, action) => {
      state.actionsLoading = false;
      state.transactions = action.payload.transactions;
      state.transactionCount = action.payload.totalCount;
      state.error = null;
    },
    // findBooks
    booksFetched: (state, action) => {
      const { totalCount, data, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.data = data;
      state.entities = entities;
      state.totalCount = totalCount;
      state.topUpSuccess = false;
      state.topUpError = false;
    },
    merchantBooksFetched:(state, action) => {
      const { totalCount, data, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.data = data;
      state.entities = entities;
      state.totalCount = totalCount;
      state.topUpSuccess = false;
      state.topUpError = false;
    },
    customerBooksFetched:(state, action) => {
      const { totalCount, data, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.data = data;
      state.entities = entities;
      state.totalCount = totalCount;
      state.topUpSuccess = false;
      state.topUpError = false;
    },
    // createBook
    bookCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.book);
    },
    // updateBook
    bookUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.updateSuccess = true;
      state.updateError = false;
      state.bookForEdit = action.payload.book;
    },
    bookUpdateError: (state, action) => {
      state.updateSuccess = false;
      state.updateError = true;
      state.actionsLoading = false;
    },
    // deleteBook
    bookDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.data = state.data.filter(el => el.id !== action.payload.id);
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteBooks
    booksDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.data = state.data.filter(
        el => !action.payload.ids.includes(el.id)
      );
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // booksUpdateState
    booksStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
      state.data = state.data.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    bookTopUpSuccess: (state, action) => {
      state.topUpSuccess = true;
      state.topUpError = false;
      state.actionsLoading = false;
    },
    bookTopUpError: (state, action) => {
      state.topUpSuccess = false;
      state.topUpError = true;
      state.actionsLoading = false;
    },
    transferMilesSuccess:(state, action) => {
      state.transferMilesSuccess = true;
      state.transferMilesError = false;
      state.actionsLoading = false;
    },
    transferMilesError:(state, action) => {
      state.transferMilesSuccess = false;
      state.transferMilesError = true;
      state.actionsLoading = false;
    },
    redeemGiftcardSuccess: (state, action) => {
      state.redeemGiftcardSuccess = true;
      state.redeemGiftcardError = false;
      state.actionsLoading = false
    },
    redeemGiftcardError: (state, action) => {
      state.redeemGiftcardSuccess = false;
      state.redeemGiftcardError = true;
      state.actionsLoading = false
    }
  }
});

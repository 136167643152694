import React, { createContext, useContext, useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import getAllPartners from "./_redux/partners/partnersCrud";
import _ from "lodash";

const PartnersContext = createContext({
  partners: [],
});

const PartnersProvider = (props) => {
  const { scope, user } = useSelector((state) => state.auth, shallowEqual);
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    getAllPartners().then((data) => {
        const partners = data.data.data
        const sortedPartners = _(partners).chain()
          .sortBy(partner => partner.abbreviation)
          .sortBy(partner => partner.industry).value();
        setPartners(sortedPartners)
      }
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPartnerNameById = (partnerId) => {
    const defaultPartnerName = "Unknown Partner";

    if (scope !== "divitadmin") {
      return user?.partners[0]?.merchantID || defaultPartnerName;
    }

    const target = partners.find((partner) => partner.merchantID === partnerId);
    return target?.name || defaultPartnerName;
  };

  return (
    <PartnersContext.Provider
      value={{ partners, getPartnerNameById }}
      {...props}
    />
  )
};

const usePartners = () => useContext(PartnersContext);

export { PartnersProvider, usePartners };

import { createSlice } from "@reduxjs/toolkit";

const initialMissionsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  data: null,
  entities: null,
  missionForEdit: undefined,
  missionLogs: {
    totalCount: 0,
    data: []
  },
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const missionsSlice = createSlice({
  name: "missions",
  initialState: initialMissionsState,
  reducers: {
    catchError: (state, action) => {
      state.error = 'Error: ' + (
        action.payload.error.response?.data?.message
        || action.payload.error.clientMessage
        || action.payload.error.message
      );
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getMission
    missionFetched: (state, action) => {
      const { missionForEdit } = action.payload
      state.actionsLoading = false;
      state.missionForEdit = { ...missionForEdit, reward: JSON.stringify(missionForEdit.reward) };
      state.error = null;
    },
    // findMissions
    missionsFetched: (state, action) => {
      const { totalCount, data, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.data = data;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createMission
    missionCreated: (state, action) => {
      const { mission } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.missionForEdit = { ...mission, reward: JSON.stringify(mission.reward) };
      if (state.data) {
        state.data.unshift(mission);
      }
    },
    // updateMission
    missionUpdated: (state, action) => {
      const { mission } = action.payload;
      state.error = null;
      state.actionsLoading = false;
      if (state.data) {
        state.data = state.data.map(entity => {
          if (entity.missionID === mission.missionID) {
            return mission;
          }
          return entity;
        });
        state.entities = state.data;
      }
    },
    customerMissionsFetched: (state, action) => {
      const { totalCount, data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.missionLogs.data = data;
      state.missionLogs.totalCount = totalCount;
    }
  }
});

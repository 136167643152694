/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import SVG from "react-inlinesvg";
import moment from "moment";

import { toAbsoluteUrl } from "../../../_helpers";
import * as ticketsActions from "../../../../app/modules/Tickets/_redux/tickets/ticketsActions";

export function DivitFeedWidget({ service, className, orderId, ticket }) {
  const dispatch = useDispatch();
  const [showComments, setShowComments] = useState(false);
  const [content, setContent] = useState("");
  const [internal, setInternal] = useState(false);

  const {
    issueID,
    title,
    content: { author, body },
    created,
    status,
    comments,
  } = ticket;

  const onClickComments = () => {
    if (comments.length > 0) {
      setShowComments(true);
    }
  };

  const submitComment = (e) => {
    e.preventDefault();
    const payload = {
      orderId,
      ticketId: issueID,
      comment: {
        content,
        private: internal,
      },
    };
    dispatch(ticketsActions.createComment(service, payload)).then(
      setContent("")
    );
  };

  return (
    <div className={`card card-custom gutter-b ${className}`}>
      {/* begin::Body*/}
      <div className="card-body">
        {/* begin::Top*/}
        <div className="d-flex align-items-center">
          {/* begin::Info*/}
          <div className="d-flex flex-column flex-grow-1">
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <a
                  href={`https://divit.atlassian.net/browse/${issueID}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-dark-75 text-hover-primary font-size-lg font-weight-bolder"
                >
                  {issueID} {title}
                </a>
              </div>
              <div className="d-flex align-items-center">
                <span
                  className={`label label-lg label-light-${
                    status === "Open" ? "info" : "danger"
                  } label-inline ml-3`}
                >
                  {status}
                </span>
              </div>
            </div>
            <div className="d-flex mt-2">
              <div className="d-flex align-items-center pr-5">
                <span className="svg-icon svg-icon-md svg-icon-primary pr-1">
                  {/* begin::Svg Icon | path:assets/media/svg/icons/General/User.svg*/}
                  <SVG
                    src={toAbsoluteUrl(`/media/svg/icons/General/User.svg`)}
                  />
                  {/* end::Svg Icon*/}
                </span>
                <span className="text-muted font-weight-bold">{author}</span>
              </div>
              <div className="d-flex align-items-center">
                <span className="svg-icon svg-icon-md svg-icon-primary pr-1">
                  {/* begin::Svg Icon | path:assets/media/svg/icons/Home/Clock.svg*/}
                  <SVG src={toAbsoluteUrl(`/media/svg/icons/Home/Clock.svg`)} />
                  {/* end::Svg Icon*/}
                </span>
                <span className="text-muted font-weight-bold">
                  {moment(created).format("YYYY-MM-DD HH:mm:ss")}
                </span>
              </div>
            </div>
          </div>
          {/* end::Info*/}
        </div>
        {/* end::Top*/}
        {/* begin::Bottom*/}
        <div>
          {/* begin::Text*/}
          <pre
            className="text-dark-75 font-weight-normal mt-5 mb-7"
            style={{ whiteSpace: "pre-wrap" }}
          >
            {body}
          </pre>
          {/* end::Text*/}
          {/* begin::Action*/}
          {!showComments && (
            <div
              className="btn btn-hover-text-primary btn-hover-icon-primary btn-sm btn-text-dark-50 bg-hover-light-primary rounded font-weight-bolder font-size-sm p-2"
              onClick={onClickComments}
            >
              <span className="svg-icon svg-icon-md svg-icon-dark-25 pr-2">
                {/* begin::Svg Icon | path:assets/media/svg/icons/Communication/Group-chat.svg*/}
                <SVG
                  src={toAbsoluteUrl(
                    `/media/svg/icons/Communication/Group-chat.svg`
                  )}
                />
                {/* end::Svg Icon*/}
              </span>
              {comments.length > 0 && `${comments.length} Comments`}
              {comments.length <= 0 && "No Comments"}
            </div>
          )}
          {/* end::Action*/}
        </div>
        {/* end::Bottom*/}
        {/* start::Comments*/}
        {showComments &&
          comments.map((comment, i) => {
            const {
              created,
              comment: { author, body },
            } = comment;
            return (
              <div className="border-top pt-3 pl-5" key={`${issueID}-${i}`}>
                <div className="d-flex">
                  <div className="d-flex align-items-center pr-5">
                    <span className="svg-icon svg-icon-md svg-icon-primary pr-1">
                      {/* begin::Svg Icon | path:assets/media/svg/icons/General/User.svg*/}
                      <SVG
                        src={toAbsoluteUrl(`/media/svg/icons/General/User.svg`)}
                      />
                      {/* end::Svg Icon*/}
                    </span>
                    <span className="text-muted font-weight-bold">
                      {author}
                    </span>
                  </div>
                  <div className="d-flex align-items-center">
                    <span className="svg-icon svg-icon-md svg-icon-primary pr-1">
                      {/* begin::Svg Icon | path:assets/media/svg/icons/Home/Clock.svg*/}
                      <SVG
                        src={toAbsoluteUrl(`/media/svg/icons/Home/Clock.svg`)}
                      />
                      {/* end::Svg Icon*/}
                    </span>
                    <span className="text-muted font-weight-bold">
                      {moment(created).format("YYYY-MM-DD HH:mm:ss")}
                    </span>
                  </div>
                </div>
                <pre
                  className="text-dark-75 font-weight-normal my-5"
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  {body}
                </pre>
              </div>
            );
          })}
        {/* end::Comments*/}
        {/* begin::Separator*/}
        <div className="separator separator-solid mt-6 mb-4"></div>
        {/* end::Separator*/}
        {/* begin::Editor*/}
        <form className="position-relative" onSubmit={submitComment}>
          <input
            value={content}
            onChange={(e) => setContent(e.target.value)}
            className="form-control border-0 p-0 pr-10 resize-none"
            placeholder="Type your message"
          />
          <div className="position-absolute top-0 right-0 mt-n1 mr-n2">
            <span
              className="btn btn-icon btn-sm btn-hover-icon-primary"
              onClick={submitComment}
            >
              <i className="flaticon2-next icon-ms"></i>
            </span>
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <input
              id={`${issueID}-internal`}
              type="checkbox"
              value={internal}
              onChange={(e) => {
                setInternal(e.target.checked);
              }}
            />
            <label className="m-0 ml-3" htmlFor={`${issueID}-internal`}>
              Internal Message
            </label>
          </div>
        </form>
        {/* edit::Editor*/}
      </div>
      {/* end::Body*/}
    </div>
  );
}

import {createSlice} from "@reduxjs/toolkit";

const initialTicketsState = {
  loading: false,
  orderToTicket: {},
  tickets: {},
  error: null
};
export const callTypes = {
  action: "action"
};

const arrayToObject = (array, key) => 
  array.reduce((data, item) => ({
    ...data,
    [item[key]]: item,
  }), {});

export const ticketsSlice = createSlice({
  name: "tickets",
  initialState: initialTicketsState,
  reducers: {
    catchError: (state, action) => {
      state.error = 'Error: ' + (
        action.payload.error.response?.data?.message
          || action.payload.error.clientMessage
          || action.payload.error.message
      );
      state.loading = false;
    },
    startCall: (state, action) => {
      state.error = null;
      state.loading = true;
    },
    ticketsFetched: (state, action) => {
      const { orderId, tickets } = action.payload;
      state.loading = false;
      state.error = null;
      state.orderToTicket[orderId] = {
        open: tickets.open.map(({ issueID }) => issueID),
        closed: tickets.closed.map(({ issueID }) => issueID),
      };
      const open = arrayToObject(tickets.open, 'issueID');
      const closed = arrayToObject(tickets.closed, 'issueID');
      state.tickets = {
        ...state.tickets,
        ...open,
        ...closed,
      };
    },
    ticketCreated: (state, action) => {
      state.loading = false;
      state.error = null;
    },
    ticketCommented: (state, action) => {
      state.loading = false;
      state.error = null;
    },
  }
});

/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import _ from "lodash";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  const { scope, user } = useSelector((state) => state.auth, shallowEqual);
  const GIFTCARD_SERVICE = process.env.REACT_APP_ENABLE_GIFTCARD_SERVICE;

  const pages = ((s, u) => {
    // subscribed service
    const hasSubscribedPayLater = (u?.partners || []).some((partner) =>
      partner?.metadata?.subscribedServices.split("|").includes("paylater")
    );
    const hasSubscribedPayNow = (u?.partners || []).some((partner) =>
      partner?.metadata?.subscribedServices.split("|").includes("paynow")
    );
    const hasSubscribedMiles = (u?.partners || []).some((partner) =>
      partner?.metadata?.subscribedServices.split("|").includes("miles")
    );

    // modules
    const milesModule = {
      Miles: ["Campaigns", "Miles Books", "Tokens", "Batch Issue Miles"],
    };
    const partnerMilesModule = {
      Miles: ["Campaigns", "Miles Books", "Tokens"],
    };
    const ordersModule = {
      Orders: ["Pay Later", "Pay Now", "Refund", "Batch Refund"],
    };
    const giftcardsModule = {
      Giftcards: [
        "Card Orders",
        "Order Waitlist",
        "Customer Cards",
        "Card Products",
        "Card Inventory",
      ],
    };
    const reportsModule = {
      Reports: [
        "Daily Giftcard Report",
        "Daily Miles Report",
        "Weekly Miles Report",
        "Monthly Miles Report",
        "Daily Transaction Report",
        "Monthly Transaction Report",
        "Settlement Report",
        "Unallocated Fund",
      ],
    };
    const toolModule = {
      Tools: [
        "Download Customer Contacts",
        "Batch Send SMS",
        "Service Maintenance",
        "Promotion Message",
      ],
    };

    // user rights
    const DivitAdminRights = () => {
      let adminMilesModule = milesModule;
      adminMilesModule["Miles"].splice(4, 0, "Missions");
      adminMilesModule["Miles"].splice(5, 0, "Transfer Miles");

      let allowedPages = [
        "Dashboard",
        ordersModule,
        adminMilesModule,
        "Customers",
        "Merchants",
        reportsModule,
        "Financials",
        "Users",
        toolModule,
        "User Profile",
      ];
      if (GIFTCARD_SERVICE === "1") {
        allowedPages.splice(3, 0, giftcardsModule);
      }
      return allowedPages;
    };

    const PartnerAdminRights = () => {
      let allowedPages = [
        "Dashboard",
        {
          Reports: [
            "Daily Transaction Report",
            "Monthly Transaction Report",
            "Settlement Report",
          ],
        },
        "Financials",
        "Users",
        "User Profile",
        "Company Profile",
      ];
      if (hasSubscribedMiles) {
        allowedPages.splice(1, 0, partnerMilesModule);
      }
      if (hasSubscribedPayLater || hasSubscribedPayNow) {
        let module = ordersModule;
        if (!hasSubscribedPayLater) {
          module["Orders"] = module["Orders"].splice(1, 1);
        }
        if (!hasSubscribedPayNow) {
          module["Orders"].splice(1, 1);
        }
        allowedPages.splice(1, 0, module);
      }
      return allowedPages;
    };

    const AgentRights = () => {
      let allowedPages = ["Dashboard", "User Profile"];
      if (hasSubscribedPayLater || hasSubscribedPayNow) {
        let module = ordersModule;
        if (!hasSubscribedPayLater) {
          module["Orders"] = module["Orders"].splice(1, 1);
        }
        if (!hasSubscribedPayNow) {
          module["Orders"].splice(1, 1);
        }
        allowedPages.splice(1, 0, module);
      }
      return allowedPages;
    };

    switch (s) {
      case "divitadmin":
        return DivitAdminRights();
      case "partneradmin":
        return PartnerAdminRights();
      case "divitagent":
      case "partneragent":
      default:
        return AgentRights();
    }
  })(scope, user);

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {pages.map((page) => {
          const hasNextLevel = !_.isString(page);
          const levelOnePageName = hasNextLevel ? Object.keys(page)[0] : page;

          return (
            /*begin::1 Level*/
            <li
              key={levelOnePageName}
              className={`menu-item ${getMenuItemActive(
                `/${_.kebabCase(levelOnePageName)}`,
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink
                className="menu-link menu-toggle"
                to={`/${_.kebabCase(levelOnePageName)}`}
              >
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      `/media/svg/icons/Aside/${_.capitalize(
                        _.kebabCase(levelOnePageName)
                      )}.svg`
                    )}
                  />
                </span>
                <span className="menu-text">{levelOnePageName}</span>
                {hasNextLevel && <i className="menu-arrow" />}
              </NavLink>
              {hasNextLevel && (
                <div className="menu-submenu ">
                  <ul className="menu-subnav">
                    {/*begin::2 Level*/}
                    {page[levelOnePageName].map((levelTwoPageName) => {
                      return (
                        <li
                          key={levelTwoPageName}
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            `/${_.kebabCase(levelOnePageName)}/${_.kebabCase(
                              levelTwoPageName
                            )}`,
                            false
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link menu-toggle"
                            to={`/${_.kebabCase(
                              levelOnePageName
                            )}/${_.kebabCase(levelTwoPageName)}`}
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">
                              {levelTwoPageName}
                            </span>
                          </NavLink>
                        </li>
                      );
                    })}
                    {/*end::2 Level*/}
                  </ul>
                </div>
              )}
            </li>
            /*end::1 Level*/
          );
        })}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}

/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';

import { actions } from "../../_redux/authRedux";
import { logout, verifyTwoFactorToken } from "../../_redux/authCrud";
import { getErrorMessage } from "@/utils/Error";

const headerStyle = {
  fontWeight: 800,
  letterSpacing: '5px',
}

const Authenticator = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { scope, token } = useSelector(({ auth }) => auth, shallowEqual);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (scope !== "2fa") {
      history.replace("/");      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickRecovery = () => {
    history.push("/auth/recovery");
  }

  const onClickLogout = () => {
    logout(token).then(() => {
      dispatch(actions.logout());
    })
    history.push("/auth/login");
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const Schema = Yup.object().shape({
    token: Yup.string().required("Required"),
  });

  const initialValues = {
    token: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      verifyTwoFactorToken(values.token)
        .then(({ data }) => {
          const { token, expire } = data;
          setLoading(false);
          dispatch(actions.refresh(token, expire));
          dispatch(actions.twoFactorAuthenticated());
        })
        .catch((e) => {
          setLoading(false);
          setSubmitting(false);
          setStatus(getErrorMessage(e));
        });
    },
  });

  return (
    <div className="login-form login-signin">
      {/* begin::Head */}
      <div className="text-center mb-10">
        <h3 className="font-size-h1 mb-10" style={headerStyle}>
          Two Factor Verification
        </h3>
        <p className="text-muted font-weight-bold">
          Enter the 6-digit token generated by authenticator app.
        </p>
      </div>
      {/* end::Head */}
      
      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}

        <div className="form-group fv-plugins-icon-container">
          <TextField
            name="token"
            label="6-digit token"
            className={getInputClasses("token")}
            type="text"
            margin="normal"
            {...formik.getFieldProps("token")}
          />
          {formik.touched.token && formik.errors.token ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.token}</div>
            </div>
          ) : null}
        </div>

        <button
          className="btn btn-block btn-primary font-weight-bold px-9 py-4 my-3"
          type="submit"
          disabled={formik.isSubmitting}
        >
          <span>Verify</span>
          {loading && <span className="ml-3 spinner spinner-white"></span>}
        </button>
        <button
          className="btn btn-block btn-secondary font-weight-bold px-9 py-4 my-3"
          onClick={onClickRecovery}
          disabled={formik.isSubmitting}
        >
          <span>Recovery</span>
        </button>
        <button
          className="btn btn-block btn-secondary font-weight-bold px-9 py-4 my-3"
          onClick={onClickLogout}
          disabled={formik.isSubmitting}
        >
          <span>Login with another account</span>
        </button>

      </form>
      {/*end::Form*/}
    </div>
  );
}

export default Authenticator;

import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialCampaignsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  data: null,
  entities: null,
  campaignForEdit: undefined,
  merchantCampaigns: undefined,
  campaignLogs: {
    totalCount: 0,
    data: []
  },
  promoCodes: {
    actionsLoading: false,
    listLoading: false,
    totalCount: 0,
    data: []
  },
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const campaignsSlice = createSlice({
  name: "campaigns",
  initialState: initialCampaignsState,
  reducers: {
    catchError: (state, action) => {
      state.error = 'Error: ' + (
        action.payload.error.response?.data?.message
        || action.payload.error.clientMessage
        || action.payload.error.message
      );
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getCampaign
    campaignFetched: (state, action) => {
      state.actionsLoading = false;
      state.campaignForEdit = action.payload.campaignForEdit;
      state.error = null;
    },
    // findCampaigns
    campaignsFetched: (state, action) => {
      const { totalCount, data, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.data = data;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createCampaign
    campaignCreated: (state, action) => {
      const { campaign } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.campaignForEdit = campaign;
      if (state.data) {
        state.data.unshift(campaign);
      }
    },
    // updateCampaign
    campaignUpdated: (state, action) => {
      const { campaign } = action.payload;
      state.error = null;
      state.actionsLoading = false;
      if (state.data) {
        state.data = state.data.map(entity => {
          if (entity.campaignID === campaign.campaignID) {
            return campaign;
          }
          return entity;
        });
        state.entities = state.data;
      }
    },
    campaignRuleCreated: (state, action) => {
      const { rule } = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.campaignForEdit.rules.unshift(rule);
      if (state.data) {
        state.data = state.data.map(entity => {
          if (entity.campaignID === state.campaignForEdit.campaignID) {
            return state.campaignForEdit;
          }
          return entity;
        });
        state.entities = state.data;
      }
    },
    campaignRuleUpdated: (state, action) => {
      const { rule } = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.campaignForEdit.rules = state.campaignForEdit.rules.map(entity => {
        if (entity.campaignID === rule.campaignID && entity.ruleID === rule.ruleID) {
          return rule;
        }
        return entity;
      });
      if (state.data) {
        state.data = state.data.map(entity => {
          if (entity.campaignID === rule.campaignID) {
            return state.campaignForEdit;
          }
          return entity;
        });
        state.entities = state.data;
      }
    },
    campaignRuleDeleted: (state, action) => {
      const { campaignID, ruleID } = action.payload;
      state.error = null;
      state.actionsLoading = false;
      _.remove(state.campaignForEdit.rules, p => {
        return p.campaignID === campaignID && p.ruleID === ruleID;
      })
      if (state.data) {
        state.data = state.data.map(entity => {
          if (entity.campaignID === campaignID) {
            return state.campaignForEdit;
          }
          return entity;
        });
        state.entities = state.data;
      }
    },
    // campaignParticipantCreated 
    campaignParticipantCreated: (state, action) => {
      const { participant } = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.campaignForEdit.participants.unshift(participant);
      if (state.data) {
        state.data = state.data.map(entity => {
          if (entity.campaignID === state.campaignForEdit.campaignID) {
            return state.campaignForEdit;
          }
          return entity;
        });
        state.entities = state.data;
      }
    },
    // campaignParticipantDeleted
    campaignParticipantDeleted: (state, action) => {
      const { merchantID, campaignID } = action.payload;
      state.error = null;
      state.actionsLoading = false;
      _.remove(state.campaignForEdit.participants, p => {
        return p.merchantID === merchantID;
      })
      if (state.data) {
        state.data = state.data.map(entity => {
          if (entity.campaignID === campaignID) {
            return state.campaignForEdit;
          }
          return entity;
        });
        state.entities = state.data;
      }
    },
    campaignCustomerRulesFetched: (state, action) => {
      const { totalCount, data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.campaignLogs.data = data;
      state.campaignLogs.totalCount = totalCount;
    },
    promoCodesStartCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.promoCodes.listLoading = true;
      } else {
        state.promoCodes.actionsLoading = true;
      }
    },
    campaignPromoCodesFetched: (state, action) => {
      const { totalCount, data } = action.payload;
      state.promoCodes.listLoading = false;
      state.error = null;
      state.promoCodes.data = data;
      state.promoCodes.totalCount = totalCount;
    },
    campaignPromoCodeCreated: (state, action) => {
      const { promoCode } = action.payload;
      state.error = null;
      state.promoCodes.actionsLoading = false;
      state.promoCodes.data.unshift(promoCode);
    },
    campaignPromoCodeUpdated: (state, action) => {
      const { promoCode } = action.payload;
      state.error = null;
      state.promoCodes.actionsLoading = false;
      state.promoCodes.data = state.promoCodes.data.map(entity => {
        if (entity.campaignID === promoCode.campaignID && entity.promoCode === promoCode.promoCode) {
          return promoCode;
        }
        return entity;
      });
    },
    campaignPromoCodeDeleted: (state, action) => {
      const { campaignID, promoCode } = action.payload;
      state.error = null;
      state.promoCodes.actionsLoading = false;
      _.remove(state.promoCodes.data, p => {
        return p.campaignID === campaignID && p.promoCode === promoCode;
      })
    },
  }
});

import React from "react";
import {useField, useFormikContext} from "formik";
import DatePicker from "react-datepicker";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function DatetimePickerField({ ...props }) {
  const { setFieldValue, errors, touched } = useFormikContext();
  const [field] = useField(props);
  return (
    <>
      {props.label && <label className="w-100"><b>{props.label}</b></label>}
      <DatePicker
        wrapperClassName="w-100"
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        style={{ width: "100%" }}
        {...field}
        {...props}
        selected={(field.value && (props.type === "timestamp" ? new Date(field.value * 1000) : new Date(field.value))) || null}
        onChange={val => {
          if (val) {
            setFieldValue(field.name, props.type === "timestamp" ? Math.round(val.getTime() / 1000) : val);
          } else setFieldValue(field.name, 0);
        }}
        showTimeSelect
        dateFormat="Pp"
      />
      {errors[field.name] && touched[field.name] && (
        <div className="invalid-datepicker-feedback">
          {errors[field.name].toString()}
        </div>
      )}
    </>
  );
}

import React, { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { TextField, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import moment from "moment";
import { getPayServices, getPartnerByAbbreviation, getPayServicesFromUser } from "./DashboardControlHelper";

import { usePartners } from  "../../../../app/modules/Partners/PartnersContext";
import { useDashboardControlContext } from "./DashboardControlProvider";

export const DashboardControlWidget = ({ className }) => {
  const { partners } = usePartners();
  const { scope, user } = useSelector((state) => state.auth, shallowEqual);
  
  const {
    abbreviation,
    payService,
    industry,
    supportedServices,
    fromDate,
    toDate,
    setAbbreviation,
    setIndustry,
    setPayService,
    setSupportedServices,
    setFromDate,
    setToDate,
  } = useDashboardControlContext();

  const momentMinToDate = moment(fromDate);
  const momentMaxToDate = momentMinToDate.isBefore(moment())
    ? moment()
    : momentMinToDate;

  useEffect(() => {
    if ((abbreviation || "") === "" || payService === undefined) return

    let services
    if (partners.length === 0) {
      services = getPayServicesFromUser(user)
      setSupportedServices(services || [])
    } else {
      services = getPayServices(getPartnerByAbbreviation(partners, abbreviation))
      setSupportedServices(services || [])
    }

    if (services && services.length === 1){
      setPayService(services[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abbreviation])
  
  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-body container p-0">
        <div className="card-spacer row no-gutter">
          <div className="col-12">
            <div className="text-dark-75 font-weight-bolder font-size-h4 mb-10">
              Dashboard Filter
            </div>
            <TextField
              label="From date"
              type="date"
              value={fromDate}
              className="w-100 mb-5"
              inputProps={{
                max: moment().subtract(1, "day").format("YYYY-MM-DD"),
              }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                const newFromDate = e.target.value;
                setFromDate(newFromDate);
                if (moment(newFromDate).isSameOrAfter(toDate)) {
                  setToDate(moment(newFromDate).add(1, "day").format("YYYY-MM-DD"));
                }
              }}
            />
            <TextField
              label="To date"
              type="date"
              value={toDate}
              className="w-100 mb-5"
              inputProps={{
                min: momentMinToDate.format("YYYY-MM-DD"),
                max: momentMaxToDate.format("YYYY-MM-DD"),
              }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                setToDate(e.target.value);
              }}
            />
            { (scope === "divitadmin" || scope === "divitagent") &&
              <FormControl className="w-100 mb-5">
                <InputLabel htmlFor="merchant">Merchant</InputLabel>
                <Select
                  value={abbreviation}
                  defaultValue="ALL"
                  onChange={(e) => {
                    const newAbbreviation = e.target.value;
                    const targetMerchant = partners.find((partner) => (
                      partner.abbreviation === newAbbreviation
                    ));
                    setAbbreviation(newAbbreviation)
                    setIndustry(targetMerchant?.industry || "admin");
                    setPayService("")
                  }}
                  inputProps={{
                    id: "merchant",
                  }}
                >
                  <MenuItem value="ALL">All</MenuItem>
                  { partners.map(({ abbreviation }) => (
                      (abbreviation !== "DIVIT") && <MenuItem key={abbreviation} value={abbreviation}>{abbreviation}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            }
            {
              ((scope === "divitadmin" || scope === "divitagent") && abbreviation === "ALL") &&
              <FormControl className="w-100 mb-5">
                <InputLabel htmlFor="industry">Industry</InputLabel>
                <Select 
                  value={industry ? industry : ""}
                  onChange={(e) => {
                    const newIndustry = e.target.value;
                    setIndustry(newIndustry);
                  }}
                  inputProps={{
                    id: "industry",
                  }}
                >
                  <MenuItem key="admin" value="admin">All</MenuItem>
                  <MenuItem key="airline" value="airline">Airline</MenuItem>
                  <MenuItem key="retail" value="retail">Retail</MenuItem>
                  <MenuItem key="insurance" value="insurance">Insurance</MenuItem>
                  <MenuItem key="residence" value="residence">Residence</MenuItem>
                </Select>
              </FormControl>
            }
            {
              ((scope === "divitadmin" || scope === "divitagent" || abbreviation !== "") && (supportedServices && supportedServices.length > 0)) && 
              <FormControl className="w-100">
                <InputLabel htmlFor="payService">Pay Now / Pay Later</InputLabel>
                <Select
                  value={payService ? payService : ""}
                  onChange={(e) => {
                    const newPayService = e.target.value;
                    setPayService(newPayService);
                  }}
                  inputProps={{
                    id: "payService",
                  }}
                >
                  {
                    (supportedServices || []).map((service) => (
                      <MenuItem key={service} value={service}>{service === "paylater" ? "Pay Later" : "Pay Now"}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            }
            <small className="form-text text-muted mt-5">
              <b>Note:</b> Today's data is not counted
            </small>
          </div>
        </div>
      </div>
    </div>
  );
}
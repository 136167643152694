import {createSlice} from "@reduxjs/toolkit";

const initialUsersState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  data: null,
  entities: null,
  userForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const usersSlice = createSlice({
  name: "users",
  initialState: initialUsersState,
  reducers: {
    catchError: (state, action) => {
      state.error = 'Error: ' + (
        action.payload.error.response?.data?.message
          || action.payload.error.clientMessage
          || action.payload.error.message
      );
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getUser
    userFetched: (state, action) => {
      state.actionsLoading = false;
      state.userForEdit = action.payload.userForEdit;
      if (state.userForEdit) {
        state.userForEdit.role = (state.userForEdit?.roles.filter(r => r !== "enduser"))?.[0] ?? "";
      }
      state.error = null;
    },
    // findUsers
    usersFetched: (state, action) => {
      const { totalCount, data, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.data = data;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createUser
    userCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.data.unshift(action.payload.user);
      state.entities.unshift(action.payload.user);
    },
    // updateUser
    userUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.data = state.data.map(entity => {
        if (entity.userID === action.payload.user.userID) {
          return action.payload.user;
        }
        return entity;
      });
      state.entities = state.entities.map(entity => {
        if (entity.userID === action.payload.user.userID) {
          return action.payload.user;
        }
        return entity;
      });
    },
  }
});

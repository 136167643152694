/**
 * Split dollar and cent in string from amount
 * @param {number} amount - Amount of price
 * @returns {Object} dollarAndCent
 * @returns {string} dollarAndCent.dollar. Dollar part of price
 * @returns {string} dollarAndCent.cent. Cent part of price
 */
export const DollarAndCent = (amount) => ({
  dollar: `${parseInt(amount / 100, 10)}`,
  cent: `${Math.abs(amount) % 100}`.padStart(2, '0'),
})

/**
 * Format a string with given currency and amount
 * @param {string} currency. Currency of price
 * @param {number} amount. Amount of price
 * @param {boolean} showCents. Show cents or not. Default true
 * @returns {string} formattedPrice. The formatted price
 */
export const FormattedPrice = (currency, amount, showCents = true) => {
  const sign = amount < 0 ? '-' : ''
  const { dollar, cent } = DollarAndCent(Math.abs(amount))
  const formattedDollar = dollar.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  return showCents
    ? `${sign}${currency} ${formattedDollar}.${cent}`
    : `${sign}${currency} ${formattedDollar}`
}

/**
 * Round to specific decimal places
 * @param {number} amount. Amount of price
 * @param {number} decimalPlaces. Decimal places
 * @returns {number} roundedValue. The rounded price
 */
export const Round = (amount, decimalPlaces = 0) => {
  const factor = 10 ** decimalPlaces
  return Math.round((amount + Number.EPSILON) * factor) / factor
}

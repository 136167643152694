import * as requestFromServer from "./ticketsCrud";
import {ticketsSlice, callTypes} from "./ticketsSlice";

const {actions} = ticketsSlice;

export const fetchTickets = (service, orderId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .listTickets(service, orderId)
    .then(response => {
      const { data } = response.data;
      dispatch(actions.ticketsFetched({ orderId, tickets: data }));
    })
    .catch(error => {
      error.clientMessage = "Can't find tickets";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createTicket = (service, ticket) => dispatch => {
  const orderId = ticket.orderID;
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createTicket(service, ticket)
    .then(response => {
      dispatch(actions.ticketCreated());
    })
    .then(() =>
      new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 1000);
      })
    )
    .then(() => {
      return requestFromServer.listTickets(service, orderId)
    })
    .then(response => {
      const { data } = response.data;
      dispatch(actions.ticketsFetched({ orderId, tickets: data }));
    })
    .catch(error => {
      error.clientMessage = "Can't create ticket";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createComment = (service, { orderId, ticketId, comment }) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createComment(service, ticketId, comment)
    .then(response => {
      dispatch(actions.ticketCommented());
    })
    .then(() => requestFromServer.listTickets(service, orderId))
    .then(response => {
      const { data } = response.data;
      dispatch(actions.ticketsFetched({ orderId, tickets: data }));
    })
    .catch(error => {
      error.clientMessage = "Can't create comment";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
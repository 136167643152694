import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";
import { PartnersProvider } from "./modules/Partners/PartnersContext";

const ToolsPage = lazy(() => import("./modules/Tools/ToolsPage"));

const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

const OrdersPage = lazy(() => import("./modules/Orders/OrdersPage"));

const MilesPage = lazy(() => import("./modules/Miles/MilesPage"));

const GiftcardPage = lazy(() => import("./modules/Giftcards/GiftcardsPage"));

const ReportsPage = lazy(() => import("./modules/Reports/ReportsPage"));

const FinancialsPage = lazy(() =>
  import("./modules/Financials/FinancialsPage")
);

const CustomersPage = lazy(() => import("./modules/Customers/CustomersPage"));

const MerchantsPage = lazy(() => import("./modules/Merchants/MerchantsPage"));

const UsersPage = lazy(() => import("./modules/Users/UsersPage"));

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <PartnersProvider>
        <Switch>
          {/* Redirect from root URL to /dashboard. */}
          <Redirect exact from="/" to="/dashboard" />
          <ContentRoute path="/dashboard" component={DashboardPage} />
          <Route path="/user-profile" component={UserProfilepage} />
          <Route path="/orders" component={OrdersPage} />
          <Route path="/giftcards" component={GiftcardPage} />
          <Route path="/customers" component={CustomersPage} />
          <Route path="/merchants" component={MerchantsPage} />
          <Route path="/miles" component={MilesPage} />
          <Route path="/reports" component={ReportsPage} />
          <Route path="/financials" component={FinancialsPage} />
          <Route path="/company-profile" component={MerchantsPage} />
          <Route path="/users" component={UsersPage} />
          <Route path="/tools" component={ToolsPage} />
          <Redirect to="/dashboard" />
        </Switch>
      </PartnersProvider>
    </Suspense>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import moment from "moment";

const initialReportsState = {
  listLoading: false,
  actionsLoading: false,
  stats: {
    customers: null,
  },
  settlement: {
    totalCount: 0,
    data: null,
    entities: null,
    reportForEdit: undefined,
  },
  dailyGiftcard: {
    totalCount: 0,
    data: null,
    entities: null,
    reportForEdit: undefined,
  },
  dailyMiles: {
    totalCount: 0,
    data: null,
    entities: null,
    reportForEdit: undefined,
  },
  weeklyMiles: {
    totalCount: 0,
    data: null,
    entities: null,
    reportForEdit: undefined,
  },
  monthlyMiles: {
    totalCount: 0,
    data: null,
    entities: null,
    reportForEdit: undefined,
  },
  dailyTrans: {
    totalCount: 0,
    data: null,
    entities: null,
    reportForEdit: undefined,
  },
  monthlyTrans: {
    totalCount: 0,
    data: null,
    entities: null,
    reportForEdit: undefined,
  },
  unallocatedFund: {
    totalCount: 0,
    data: null,
    entities: null,
    recordForEdit: undefined,
  },
  lastError: null,
  orderReport: null,
  customerReport: null,
  merchantMilesReport: null,
  customerMilesReport: null,
  metaData: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

const SumUpAndMerge = (map1, map2) => {
  return Object.entries(map2).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: (acc[key] || 0) + value }),
    { ...map1 }
  );
};

const PriceBucketMerge = (map1, map2) => {
  for (const [key, value] of Object.entries(map2)) {
    const bound = key.split(" - ");
    for (const [key1, value1] of Object.entries(map1)) {
      if (
        key1.startsWith(bound[0] + " - ") ||
        key1.endsWith(" - " + bound[1])
      ) {
        map1[key1] = (value1 || 0) + value;
        break;
      }
    }
  }
  return map1;
};

export const reportsSlice = createSlice({
  name: "reports",
  initialState: initialReportsState,
  reducers: {
    catchError: (state, action) => {
      state.error =
        "Error: " +
        (action.payload.error.response?.data?.message ||
          action.payload.error.clientMessage ||
          action.payload.error.message);
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    //
    customerStatsFetched: (state, action) => {
      state.listLoading = false;
      state.stats.customers = action.payload.data;
      state.error = null;
    },
    // getReport
    settlementReportFetched: (state, action) => {
      state.actionsLoading = false;
      state.settlement.reportForEdit = action.payload.reportForEdit;
      state.error = null;
    },
    // findReports
    settlementReportsFetched: (state, action) => {
      const { totalCount, data, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.settlement.data = data;
      state.settlement.entities = entities;
      state.settlement.totalCount = totalCount;
    },
    // downloadReport
    settlementReportDownloaded: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    // settlementReportUploaded
    settlementReportUploaded: (state, action) => {
      const { data } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      if (data) {
        state.settlement.data.push(data);
        state.settlement.entities.push(data);
      }
    },

    // findReports
    dailyGiftcardReportsFetched: (state, action) => {
      const { totalCount, data, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.dailyGiftcard.data = data;
      state.dailyGiftcard.entities = entities;
      state.dailyGiftcard.totalCount = totalCount;
    },
    // downloadReport
    dailyGiftcardReportDownloaded: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },

    // findReports
    dailyMilesReportsFetched: (state, action) => {
      const { totalCount, data, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.dailyMiles.data = data;
      state.dailyMiles.entities = entities;
      state.dailyMiles.totalCount = totalCount;
    },
    // downloadReport
    dailyMilesReportDownloaded: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },

    // findReports
    weeklyMilesReportsFetched: (state, action) => {
      const { totalCount, data, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.weeklyMiles.data = data;
      state.weeklyMiles.entities = entities;
      state.weeklyMiles.totalCount = totalCount;
    },
    // downloadReport
    weeklyMilesReportDownloaded: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },

    // findReports
    monthlyMilesReportsFetched: (state, action) => {
      const { totalCount, data, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.monthlyMiles.data = data;
      state.monthlyMiles.entities = entities;
      state.monthlyMiles.totalCount = totalCount;
    },
    // downloadReport
    monthlyMilesReportDownloaded: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },

    // findReports
    dailyTransReportsFetched: (state, action) => {
      const { totalCount, data, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.dailyTrans.data = data;
      state.dailyTrans.entities = entities;
      state.dailyTrans.totalCount = totalCount;
    },
    // downloadReport
    dailyTransReportDownloaded: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },

    // findReports
    monthlyTransReportsFetched: (state, action) => {
      const { totalCount, data, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.monthlyTrans.data = data;
      state.monthlyTrans.entities = entities;
      state.monthlyTrans.totalCount = totalCount;
    },
    // downloadReport
    monthlyTransReportDownloaded: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },

    // findLog
    unallocatedFundFetched: (state, action) => {
      const { totalCount, data, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.unallocatedFund.data = data;
      state.unallocatedFund.entities = entities;
      state.unallocatedFund.totalCount = totalCount;
    },
    unallocatedFundRecordFetched: (state, action) => {
      const { record } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.unallocatedFund.recordForEdit = record;
    },
    unallocatedFundToOrderAssigned: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.unallocatedFund.recordForEdit = null;
    },
    unallocatedFundRefunded: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.unallocatedFund.recordForEdit = null;
    },

    startOrderReport: (state, action) => {
      state.orderReport = null;
      state.metaData = null;
    },
    orderReportFetched: (state, action) => {
      const groupedData = _.groupBy(action.payload, (i) => i.LogDate);
      const reducedData = _.entries(groupedData).map(([key, value]) => {
        const initial = {
          LogDate: key,
          V0: 0,
          V1: 0,
          V2: 0,
          V3: 0,
          V4: 0,
          V5: 0,
          V6: 0,
          V7: 0,
          V8: 0,
          V9: 0,
        };
        const accumulatedData = value.reduce(
          (accumulator, i) => ({
            LogDate: accumulator.LogDate,
            V0: accumulator.V0 + i.V0,
            V1: accumulator.V1 + i.V1,
            V2: accumulator.V2 + i.V2,
            V3: accumulator.V3 + i.V3,
            V4: accumulator.V4 + i.V4,
            V5: accumulator.V5 + i.V5,
            V6: accumulator.V6 + i.V6,
            V7: accumulator.V7 + i.V7,
            V8: accumulator.V8 + i.V8,
            V9: accumulator.V9 + i.V9,
          }),
          initial
        );
        // recalculate order mean
        const recalculatedData = {
          ...accumulatedData,
          V3:
            accumulatedData.V0 === 0
              ? 0
              : accumulatedData.V2 / accumulatedData.V0,
        };
        return recalculatedData;
      });
      const destination = action.payload.reduce((accumulator, i) => {
        return SumUpAndMerge(accumulator, i?.Metadata?.destination || {});
      }, {});
      const distribution = action.payload.reduce(
        (accumulator, i) => {
          return PriceBucketMerge(accumulator, i?.Metadata?.distribution || {});
        },
        {
          "0 - 1000": 0,
          "1000 - 2000": 0,
          "2000 - 3000": 0,
          "3000 - 4000": 0,
          "4000 - 5000": 0,
          "5000 - 6000": 0,
          "6000 - 7000": 0,
          "7000 - 8000": 0,
          "8000 - 9000": 0,
          "9000 - 10000": 0,
          "10000 - 11000": 0,
          "11000 - 12000": 0,
          "12000 - 13000": 0,
          "13000 - 14000": 0,
          "14000 - 15000": 0,
          "15000 - 16000": 0,
          "16000 - 17000": 0,
          "17000 - 18000": 0,
          "18000 - 19000": 0,
          "19000 - 20000": 0,
          "20000 - 21000": 0,
          "21000 - 22000": 0,
          "22000 - 23000": 0,
          "23000 - 24000": 0,
          "24000 - 25000": 0,
          "25000 - 26000": 0,
          "26000 - 27000": 0,
          "27000 - 28000": 0,
          "28000 - 29000": 0,
          "29000 - 30000": 0,
        }
      );
      state.orderReport = [...reducedData].sort((a, b) => {
        return moment(a.LogDate).isAfter(moment(b.LogDate)) ? 1 : -1;
      });
      state.metaData = { destination, distribution };
    },
    startCustomerReport: (state, action) => {
      state.customerReport = null;
    },
    customerReportFetched: (state, action) => {
      const groupedData = _.groupBy(action.payload, (i) => i.LogDate);
      const reducedData = _.entries(groupedData).map(([key, value]) => {
        const initial = {
          LogDate: key,
          V0: 0,
          V1: 0,
          V2: 0,
          V3: 0,
          V4: 0,
          V5: 0,
          V6: 0,
          V7: 0,
          V8: 0,
          V9: 0,
        };
        const accumulatedData = value.reduce(
          (accumulator, i) => ({
            LogDate: accumulator.LogDate,
            V0: accumulator.V0 + i.V0,
            V1: accumulator.V1 + i.V1,
            V2: accumulator.V2 + i.V2,
            V3: accumulator.V3 + i.V3,
            V4: accumulator.V4 + i.V4,
            V5: accumulator.V5 + i.V5,
            V6: accumulator.V6 + i.V6,
            V7: accumulator.V7 + i.V7,
            V8: accumulator.V8 + i.V8,
            V9: accumulator.V9 + i.V9,
          }),
          initial
        );
        return accumulatedData;
      });
      state.customerReport = [...reducedData].sort((a, b) => {
        return moment(a.LogDate).isAfter(moment(b.LogDate)) ? 1 : -1;
      });
    },
    startMerchantMilesReport: (state, action) => {
      state.merchantMilesReport = null;
    },
    merchantMilesReportFetched: (state, action) => {
      const groupedData = _.groupBy(action.payload, (i) => i.logDate);
      const reducedData = _.entries(groupedData).map(([key, value]) => {
        const initial = {
          logDate: key,
          v0: 0,
          v1: 0,
          v2: 0,
          v3: 0,
          v4: 0,
          v5: 0,
          v6: 0,
          v7: 0,
          v8: 0,
          v9: 0,
        };
        const accumulatedData = value.reduce(
          (accumulator, i) => ({
            logDate: accumulator.logDate,
            v0: accumulator.v0 + i.v0,
            v1: accumulator.v1 + i.v1,
            v2: accumulator.v2 + i.v2,
            v3: accumulator.v3 + i.v3,
            v4: accumulator.v4 + i.v4,
            v5: accumulator.v5 + i.v5,
            v6: accumulator.v6 + i.v6,
            v7: accumulator.v7 + i.v7,
            v8: accumulator.v8 + i.v8,
            v9: accumulator.v9 + i.v9,
          }),
          initial
        );
        return accumulatedData;
      });
      state.merchantMilesReport = [...reducedData].sort((a, b) => {
        return moment(a.logDate).isAfter(moment(b.logDate)) ? 1 : -1;
      });
    },
    startCustomerMilesReport: (state, action) => {
      state.customerMilesReport = null;
    },
    customerMilesReportFetched: (state, action) => {
      state.customerMilesReport = [...action.payload].sort((a, b) => {
        return moment(a.logDate).isAfter(moment(b.logDate)) ? 1 : -1;
      });
    },
  },
});

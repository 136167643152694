import * as requestFromServer from "./merchantsCrud";
import { merchantsSlice, callTypes } from "./merchantsSlice";

const { actions } = merchantsSlice;

export const fetchMerchants = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findMerchants(queryParams)
    .then((response) => {
      const data = response.data.data;
      const totalCount = response.data.count || 0;
      dispatch(actions.merchantsFetched({ totalCount: totalCount, data: data, entities: data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find merchants";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchMerchant = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.merchantFetched({ merchantForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getMerchantById(id)
    .then((response) => {
      const merchant = response.data.data;
      dispatch(actions.merchantFetched({ merchantForEdit: merchant }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find merchant";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const toggleMerchantStatus = (merchant) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateMerchant(merchant.merchantID, { status: merchant.status === "active" ? "inactive" : "active" })
    .then((response) => {
      const merchant = response.data.data;
      dispatch(actions.merchantUpdated({ merchant: merchant }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update merchant";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
}

export const createMerchant = (payload) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createMerchant(payload)
    .then((response) => {
      const data = response.data
      dispatch(actions.merchantCreated({ data }));
    })
    .catch((error) => {
      error.clientMessage = "create merchant failed";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateMerchant = (merchantID, merchant) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateMerchant(merchantID, { ...merchant })
    .then((response) => {
      const merchant = response.data.data;
      dispatch(actions.merchantUpdated({ merchant: merchant }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update merchant";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
}

export const getMerchantDoc = (merchantID, filename) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getMerchantDoc(merchantID, filename)
    .then(({ data }) => {
      const blob = new Blob([data]);
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = filename;
      a.click();
      dispatch(actions.merchantDocDownloaded());
    }).catch((error) => {
      error.clientMessage = "file is not found or not ready yet";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
}

export const getMerchantCustomerData = merchantID => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getMerchantCustomerData(merchantID)
    .then(({ data, type }) => {
      const blob = new Blob([data], { type });
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = "customer_data.csv";
      a.click();
      dispatch(actions.merchantCustomerDataDownloaded());
    }).catch((error) => {
      error.clientMessage = "file is not found or not ready yet";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
}

export const fetchMerchantRedirectUrls = merchantID => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
  .getMerchantRedirectUrls(merchantID)
  .then((response) => {
    const data = [];
    (response.data.data || []).forEach(url => data.push({url: url}))
    dispatch(actions.merchantRedirectUrlsFetched({ data: data }));
  })
  .catch((error) => {
    error.clientMessage = "Can't load merchant redirectUrls";
    dispatch(actions.catchError({ error }));
  });
}

export const addMerchantRedirectUrl = (merchantID, rec) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .addMerchantRedirectUrl(merchantID, rec)
    .then((response) => {
      const data = [];
      (response.data.data || []).forEach(url => data.push({url: url}))
      dispatch(actions.merchantRedirectUrlsFetched({ data: data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't add merchant redirectUrl";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
}

export const removeMerchantRedirectUrl = (merchantID, rec) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .removeMerchantRedirectUrl(merchantID, rec)
    .then((response) => {
      const data = [];
      (response.data.data || []).forEach(url => data.push({url: url}))
      dispatch(actions.merchantRedirectUrlsFetched({ data: data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't add merchant redirectUrl";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
}

export const fetchMerchantRates = (merchantID, service) => (dispatch) => {
  return requestFromServer
    .getMerchantRates(merchantID, service)
    .then((response) => {
      const data = response.data.data;
      const totalCount = response.data.count || 0;
      if (service === "paynow") {
        dispatch(actions.merchantPaynowRatesFetched({ totalCount: totalCount, data: data }));
      }
      if (service === "paylater") {
        dispatch(actions.merchantPaylaterRatesFetched({ totalCount: totalCount, data: data }));
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't load merchant rates";
      dispatch(actions.catchError({ error }));
    });
}

export const editMerchantRate = (rec) => (dispatch) => {
  dispatch(actions.editMerchantPaynowRate(rec))
}

export const updateMerchantRate = (merchantID, service, rec) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateMerchantRate(merchantID, service, rec.rateID, rec)
    .then((response) => {
      const merchantRate = response.data.data;
      if (service === "paynow") {
        dispatch(actions.merchantPaynowRateUpdated({ merchantRate: merchantRate }));
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't update merchant rate";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
}

export const fetchMerchantEventSignature = (merchantID) => (dispatch) => {
  return requestFromServer
    .getMerchantEventSignature(merchantID)
    .then((response) => {
      const data = response.data.data;
      dispatch(actions.merchantEventSignatureFetched({ data: data }));
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        // not found error is okay
        if (error.response.data.code !== 4023) {
          error.clientMessage = `${error.response.data.code}:${error.response.data.message}`
        } else {
          dispatch(actions.merchantEventSignatureFetched({ data: { signatureKey: "" }}));
        }
      } else {
        error.clientMessage = "Can't load merchant Event Signature";
      }
      if (error.clientMessage) {
        dispatch(actions.catchError({ error }));
      }
    })
}

export const updateMerchantEventSignature = (merchantID, rec) => (dispatch) => {
  const param = { key: rec.signatureKey}
  return requestFromServer
    .updateMerchantEventSignature(merchantID, param)
    .then((response) => {
      const data = response.data.data;
      dispatch(actions.merchantEventSignatureUpdated({ data: data }));
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        error.clientMessage = `${error.response.data.code}:${error.response.data.message}`
      } else {
        error.clientMessage = "Can't update merchant Event Signature";
      }
      dispatch(actions.catchError({ error }));
    })
}

export const fetchMerchantAPIKeys = (merchantID) => (dispatch) => {
  return requestFromServer
    .getMerchantAPIKeys(merchantID)
    .then((response) => {
      const data = response.data.data;
      const totalCount = response.data.count || 0;
      dispatch(actions.merchantAPIKeysFetched({ totalCount: totalCount, data: data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't load merchant API keys";
      dispatch(actions.catchError({ error }));
    });
}

export const editMerchantAPIKey = (rec) => (dispatch) => {
  dispatch(actions.editMerchantAPIKey(rec))
}

export const updateMerchantAPIKey = (merchantID, rec) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateMerchantAPIKey(merchantID, rec.keyID, rec)
    .then((response) => {
      const merchantAPIKey = response.data.data;
      dispatch(actions.merchantAPIKeyUpdated({ merchantAPIKey: merchantAPIKey }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update merchant API key";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
}

export const fetchMerchantExchangeRates = (merchantID) => (dispatch) => {
  return requestFromServer
    .getMerchantExchangeRates(merchantID)
    .then((response) => {
      const data = response.data.data;
      const totalCount = response.data.count || 0;
      dispatch(actions.merchantExchangeRatesFetched({ totalCount: totalCount, data: data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't load merchant exchange rates";
      dispatch(actions.catchError({ error }));
    });
}

export const editMerchantExchangeRate = (rec) => (dispatch) => {
  dispatch(actions.editMerchantExchangeRate(rec))
}

export const updateMerchantExchangeRate = (merchantID, rec) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateMerchantExchangeRate(merchantID, rec.rateID, rec)
    .then((response) => {
      const exchangeRate = response.data.data;
      dispatch(actions.merchantExchangeRateUpdated({ exchangeRate: exchangeRate }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update merchant exchange rate";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
}

export const fetchMerchantShopifyConfigs = (merchantID) => (dispatch) => {
  return requestFromServer
    .getMerchantShopifyConfigs(merchantID)
    .then((response) => {
      const data = response.data.data;
      const totalCount = response.data.count || 0;
      dispatch(actions.merchantShopifyConfigsFetched({ totalCount: totalCount, data: data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't load merchant shopify config";
      dispatch(actions.catchError({ error }));
    });
}
export const editMerchantShopifyConfig = (rec) => (dispatch) => {
  dispatch(actions.editMerchantShopifyConfig(rec))
}
export const upsertMerchantShopifyConfig = (merchantID, rec) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .upsertMerchantShopifyConfig(merchantID, rec.shopDomain, rec.accessToken, rec.metadata)
    .then((response) => {
      dispatch(actions.merchantShopifyConfigUpdated({ data: response.data.data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update merchant Shopify config";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
}
export const deleteMerchantShopifyConfig = (merchantID, rec) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteMerchantShopifyConfig(merchantID, rec.shopDomain)
    .then(() => {
      dispatch(actions.merchantShopifyConfigDeleted({ merchantID, shopDomain: rec.shopDomain}));
    })
    .catch((error) => {
      error.clientMessage = "Can't update merchant Shopify config";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
}

export const getPayServices = (partner) => {
    const subscribedServices = partner?.subscribedServices
    if (subscribedServices) {
        const s = subscribedServices.split("|").filter(v => v === "paynow" || v === "paylater") 
        if (s && s.length > 0) return s
        return []
    }
    return ["paylater","paynow"]
}

export const getPartnerByAbbreviation = (partners, abbreviation) => {
    const p = partners.filter(p => p.abbreviation === abbreviation)
    return p ? p[0] : null;
}


export const getPayServicesFromUser = (user) => {
    if (!user?.partners) return ["paylater","paynow"]
    const subscribedServices = user?.partners[0]?.metadata?.subscribedServices
    if (subscribedServices) {
        const s = subscribedServices.split("|").filter(v => v === "paynow" || v === "paylater") 
        if (s && s.length > 0) return s
    }
    return []
}
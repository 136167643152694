import React, {useEffect, useMemo, useState} from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {useHtmlClassService} from "@/_metronic/layout/_core/MetronicLayout";
import { useFormik } from "formik";
import {ThemeProvider} from "@material-ui/styles";
import { createMuiTheme, makeStyles, styled } from "@material-ui/core";
import {
    TextField, Button
} from "@material-ui/core";
import InputMask from "react-input-mask";
import SVG from "react-inlinesvg";
import * as Yup from "yup"; 
import 'yup-phone-lite';
import { toAbsoluteUrl } from "@/_metronic/_helpers";
import { Modal } from "react-bootstrap";
import { CaptchaProvider, useCaptcha } from "./CaptchaProvider";
import { signup } from "./_redux/signupActions";
import Agreement from "./Agreement";

const theme = createMuiTheme({
    palette: {},
});
const useStyles = makeStyles(theme => ({
    root: {
        fontFamily:"Noto Sans, sans-serif",
        color: "#1c1c1c"
    },
    title: {
        fontFamily: "NexaBold, Noto Sans, sans-serif",
        color: "#1c1c1c",
        fontSize:"3rem",
        fontWeight: 500,
        letterSpacing: "0.08rem"
    },
    logo: {
        background:"white", 
        paddingLeft: "100px",
        paddingTop: "15px",
        paddingBottom:"20px",
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        borderBottomColor: "#ddd"
    },
    content: {
        height: "100%",
        width: "60%",
        minWidth: "720px",
        backgroundColor: "white",
        padding: "100px",
    },
    terms: {
        textDecoration: "underline",
        color: "#1c1c1c",
        "&:hover": {
            color: "#ffcc33",
        }
    },
    upload: {
        "&:hover": {
            color: "white",
            backgroundColor: "#1c1c1c",
        },
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#ccc",
    },
    svg: {
        filter: "brightness(0.1)",
        "&:hover": {
            filter: "brightness(100)"
        }
    },
}));

const StyledTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: 'black',
    },
    '& input:valid:focus + fieldset': {
        borderColor: 'black',
        borderWidth: '1px',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'black',
        borderWidth: '1px',
    }
});
const StyledButton = styled(Button) ({
    fontFamily: "NexaBold, Noto Sans, sans-serif",
    fontSize: "1.2rem",
    fontWeight: 500,
    color: "#1c1c1c",
    borderRadius: "1.8rem",
    backgroundColor: "#ffcc33",
    textTransform: "lowercase",
    padding: "0.8rem 3rem",
    paddingBottom: "0.75rem",
    '&:hover': {
        backgroundColor: '#1c1c1c',
        color: 'white',
    }
});
function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
} 

const ASSET_URL = process.env.REACT_APP_ASSET_URL

function SignupPage() {
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            brandClasses: uiService.getClasses("brand", true),
            headerLogo: uiService.getLogo(),
            headerStickyLogo: uiService.getStickyLogo()
        };
    }, [uiService]);
    const dispatch = useDispatch();
    const { actionsLoading, completed, error } = useSelector(
        (state) => ({
          ...state.signup,
        }),
        shallowEqual
      );
    
    const query = useQuery();
    const classes = useStyles();
    const inputFile = React.useRef(null);
    const [showModal, setShowModel] = useState(false);
    const { executeRecaptcha } = useCaptcha()
    const history = useHistory();
    const ts = Math.round((new Date()).getTime() / 1000);
    
    const SignupSchema = Yup.object().shape({
        email: Yup.string().email().required('please input valid email'),
        tel: Yup.string().phone('HK').required('please input valid contact number'),
        contact: Yup.string().required("required"),
        company: Yup.string().required("required"),
        brNumber: Yup.string().required("required"),
        file: Yup.mixed()
            .nullable()
            .notRequired()
            .test("FILE_SIZE", "uploaded file is too big.", 
                value => !value || (value && value.size <= 2048*1024))
            .test("FILE_FORMAT", "uploaded file has unsupported format.", 
                value => !value || (value && value.type === "application/pdf"))
    })
    const initialValues = {
        email:"",
        tel: "",
        contact: "",
        company:"",
        brNumber: "",
        accNumber: "",
        file: null
    }
    const formik = useFormik({
        initialValues,
        validationSchema: SignupSchema,
        onSubmit: async (values) => {
            let recapResp = '';
            try {
                recapResp = await executeRecaptcha('MERCHANT_SIGNUP');
            } catch (err) {
                console.error(err);
            }
            dispatch(signup({ ...values, recapResp}))
        }
    });
    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
          return "is-invalid";
        }
        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
          return "is-valid";
        }
        return "";
    };

    useEffect(() => {
        if (query.get("email") !== null) {
            formik.setFieldValue("email", query.get("email"))
        }
        return () => {
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

    return (
        <ThemeProvider theme={theme}>
            <div id="root" className={`${classes.root}`}>
                {/* Logo */}
                <div className={`${classes.logo} ${layoutProps.brandClasses}`}>
                    <img alt="logo" src={layoutProps.headerLogo} style={{height: "36px"}} />
                </div>
                <div className={`flex-column-fluid ${classes.content}`}>
                {
                    !completed &&
                    <form onSubmit={formik.handleSubmit}>
                        {formik.status && (
                            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                                <div className="alert-text font-weight-bold">{formik.status}</div>
                            </div>
                        )}
                        <div className="mb-2">just one more step to finish setting up</div>
                        <div className="col-sm-8 pl-0"><h1 className={classes.title}>tell us about your company</h1></div>
                        {
                            error && <div className={`alert alert-danger`} role="alert">
                                <div className="alert-text">{error}</div>
                            </div>
                        }
                        <div className="form-row">
                            <div className="col-sm-8">
                                <StyledTextField
                                    name="email"
                                    label="email"
                                    placeholder="email"
                                    className={getInputClasses("email")}
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        animated: "true",
                                    }}
                                    {...formik.getFieldProps("email")}
                                    required
                                />
                                {formik.touched.email && formik.errors.email ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.email}</div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-sm-8">
                                <StyledTextField
                                    name="company"
                                    label="company name"
                                    placeholder="company name"
                                    className={getInputClasses("company")}
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                    animated: "true",
                                    }}
                                    {...formik.getFieldProps("company")}
                                    required
                                />
                                {formik.touched.company && formik.errors.company ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.company}</div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-sm-8">
                                <StyledTextField
                                    name="brNumber"
                                    label="business registration number"
                                    placeholder="business registration number"
                                    className={getInputClasses("name")}
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        animated: "true",
                                    }}
                                    {...formik.getFieldProps("brNumber")}
                                    required
                                />
                                {formik.touched.brNumber && formik.errors.brNumber ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.brNumber}</div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-sm-8 align-items-center mt-2">
                                {/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */}
                                <a title="upload BR document"
                                    className={`btn btn-icon btn-sm mr-3 ${classes.upload}`}
                                    onClick={() => {inputFile.current.click()}}
                                >
                                    <span className={`svg-icon svg-icon-md ${classes.svg}`}>
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/Files/Cloud-upload.svg")}
                                    />
                                    </span>
                                </a> 
                                {
                                    formik.values.file === null && <span>business registration document in pdf format</span>
                                }
                                {
                                    formik.values.file !== null && <>
                                        <span>{ formik.values.file.name }</span>
                                        <button type="button" className="close" style={{padding: "5px"}} onClick={(e) => {
                                            formik.setFieldValue("file", null);
                                            inputFile.current.value = ""
                                        }}>
                                            <span aria-hidden="true">×</span>
                                            <span className="sr-only">Clear</span>
                                        </button>
                                    </>
                                }
                                <input ref={inputFile} type="file" id="file" name="file" accept=".pdf" onChange={(e) => {
                                    formik.setFieldValue("file", e.currentTarget.files[0]);
                                }} style={{display:"none"}} />
                                {formik.touched.file && formik.errors.file ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.file}</div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-sm-8">
                                <StyledTextField
                                    name="accNumber"
                                    label="bank account number"
                                    placeholder="bank account number"
                                    className={getInputClasses("accNumber")}
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        animated: "true",
                                    }}
                                    {...formik.getFieldProps("accNumber")}
                                />
                                {formik.touched.accNumber && formik.errors.accNumber ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.accNumber}</div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-sm-8">
                                <StyledTextField
                                    name="contact"
                                    label="contact person"
                                    placeholder="contact person"
                                    className={getInputClasses("contact")}
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        animated: "true",
                                    }}
                                    {...formik.getFieldProps("contact")}
                                    required
                                />
                                {formik.touched.contact && formik.errors.contact ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.contact}</div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-sm-8">
                                <InputMask mask="9999 9999" value={formik.values.tel} onChange={(e) => {
                                    formik.setFieldValue("tel", e.target.value)
                                }}>
                                    { () => <StyledTextField
                                            name="tel"
                                            label="phone number"
                                            placeholder="phone number"
                                            className={getInputClasses("tel")}
                                            margin="normal"
                                            variant="outlined"
                                            InputLabelProps={{
                                                animated: "true",
                                            }}
                                            value={formik.values.tel}
                                            required
                                        />}
                                </InputMask>
                                {formik.touched.tel && formik.errors.tel ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.tel}</div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="form-row mt-5">
                            <div className="form-group col-sm-6">
                                <StyledButton
                                    type="submit"
                                    disabled={formik.isSubmitting || actionsLoading}
                                >
                                    <span>create account</span>
                                    {formik.isSubmitting && <span className="ml-3 spinner spinner-white"></span>}
                                </StyledButton>
                            </div>
                        </div>
                        <div className="form-row ml-1 mb-50">
                            {/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */}
                            <div>by continuing, you're agreeing to our <a className={classes.terms} onClick={()=>{setShowModel(true) }}>terms and conditions</a></div>
                        </div>
                    </form>
                }
                {
                    completed && 
                    <>
                        <div className="col-sm-8 pl-0"><h1 className={classes.title}>start using your digital account with the manual process</h1></div>
                        <div className="mb-5">Lorem ipsum is a pseudo-Latin text used in web design, typography, layout, and printing in place of English to emphasise design elements over content. It's also called placeholder (or filler) text. It's a convenient tool for mock-ups. It helps to outline the visual elements of a document or presentation, eg typography, font, or layout. Lorem ipsum is mostly a part of a Latin text by the classical author and philosopher Cicero. Its words and letters have been changed by addition or removal, so to deliberately render its content nonsensical.</div>
                        <div className="mb-5">
                            <a href="https://divit.com.hk/contact" target="_blank" rel="noopener noreferrer">Contact divit</a> to integrate with your commerce platform, and we will contact you in 24 hours
                        </div>
                        <div className="form-row mt-5">
                            <div className="form-group col-sm-6">
                                <StyledButton onClick={()=>{ history.replace("/auth/login")}}>
                                    <span>See my portal</span>
                                </StyledButton>
                            </div>
                        </div>
                    </>
                }
                </div>
                <Modal size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={showModal}
                    onHide={() => { setShowModel(false) }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Terms and conditions</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{height: "480px"}}><Agreement /></div>
                    </Modal.Body>
                    <Modal.Footer>
                        { /* eslint-disable-next-line react/jsx-no-target-blank */ }
                        <a href={`${ASSET_URL}/agreements/merchant_agreement.pdf?t=${ts}`} target="_blank" className={classes.terms} onClick={() => {setShowModel(false) }}>Download Merchant Agreement</a>
                    </Modal.Footer>
                </Modal>
            </div>
        </ThemeProvider>
    );
}

const SignupPageClass = props => (
    <CaptchaProvider>
        <SignupPage {...props} />
    </CaptchaProvider>
)
  
export default SignupPageClass
import React, {createContext, useEffect, useContext, useState, useCallback} from "react";
import { useLocation } from "react-router-dom";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./OrdersUIHelpers";

const OrdersUIContext = createContext();

export function useOrdersUIContext() {
  return useContext(OrdersUIContext);
}

export const OrdersUIConsumer = OrdersUIContext.Consumer;
export const UUIDNil = "00000000-0000-0000-0000-000000000000";

export function OrdersUIProvider({ordersUIEvents, children}) {
  const location = useLocation();
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const [customerID, setCustomerID] = useState();
  const [merchantID, setMerchantID] = useState();
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  useEffect(() => {
    if (location?.state?.customerID) {
      setCustomerID(location?.state?.customerID);
    } else {
      setCustomerID("");
    }
    if (location?.state?.merchantID) {
      setMerchantID(location?.state?.merchantID);
    } else {
      setMerchantID("");
    }
  }, [location]);

  const initOrder = {
    id: undefined,
    status: "",
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initOrder,
    customerID,
    merchantID,
    setCustomerID,
    setMerchantID,
    newOrderButtonClick: ordersUIEvents.newOrderButtonClick,
    openEditOrderDialog: ordersUIEvents.openEditOrderDialog,
    openDeleteOrderDialog: ordersUIEvents.openDeleteOrderDialog,
    openDeleteOrdersDialog: ordersUIEvents.openDeleteOrdersDialog,
    openFetchOrdersDialog: ordersUIEvents.openFetchOrdersDialog,
    openUpdateOrdersStatusDialog: ordersUIEvents.openUpdateOrdersStatusDialog,
    viewOrder: ordersUIEvents.viewOrder
  };

  return <OrdersUIContext.Provider value={value}>{children}</OrdersUIContext.Provider>;
}